import invariant from 'invariant';
import _, { shuffle, times } from 'underscore';

import { findReverse, maxBy, nonNull } from '@mythos/utils/utils';
import nullthrows from 'nullthrows';
import Deck from './Deck';
import * as GameConstants from './GameConstants';
import { migrateCardChars2To3 } from './MigrationUtils';
import { nextPhase, Phase, PhaseOrder, Phases } from './Phases';
import Player, { RenderedPlayer } from './Player';
import * as Rules from './Rules';
import * as TributeLogic from './TributeLogic';
import * as Utility from './Utility';

import { match } from 'ts-pattern';
import { getAllCardAbilities } from './CardAbilities';
import {
  assertCardAffinity,
  assertCardType,
  CardAffinity,
  CardType,
} from './CardTypes';
import { ActionTypes } from './GameActions';
import {
  ConflictRoundInfo,
  EventTypes,
  GameEvent,
  GameEventData,
} from './GameEvents';
import { CardDef, mergeModuleCards, ModuleDef, TributeDef } from './GameModel';
import { GameOptions } from './GameOptions';
import { Token } from './Resources';
let DEBUG_STARTING_CARDS: string[] = [];

let DEBUG_TRADE_ROW_CARDS: string[] = [];

let TEST_TRIBUTES: string[] = [];

function roll_die(): number {
  return Math.floor(Math.random() * 6) + 1;
}

type RenderedGame = ReturnType<Game['render']>;
export type InflatedGame = ReturnType<typeof Game.inflateGame>;
export type InflatedPlayer = ReturnType<typeof Game.inflatePlayer>;

type CardDefWithIndex = {
  index: number;
} & CardDef;
type TributeDefWithIndex = {
  index: number;
} & TributeDef;

export const GAME_VERSION = 3;

export default class Game {
  static AGES_PER_GAME = GameConstants.AGES_PER_GAME;

  id_: string;
  players_: Player[];
  options_: GameOptions;

  lastCardID_: number = 100;
  cardsByID_: { [key: string]: Rules.CardWithID } = {};
  tributeCardsByID_: { [key: string]: Rules.TributeCardWithID } = {};

  deck_: Deck<Rules.CardWithID> = new Deck();
  tributeDeck_: Deck<Rules.TributeCardWithID> = new Deck();
  table_: Rules.CardWithID[] = [];
  basicPile_: Rules.CardWithID[] = [];
  tributeRow_: Rules.TributeCardWithID[] = [];
  discard_: Rules.CardWithID[] = [];
  tributeDiscard_: Rules.TributeCardWithID[] = [];

  age_: number = 0;
  turn_: number = 0;
  phase_: Phase = Phases.PLANNING;

  gameStartTimestamp_: number | null = null;
  gameEndTimestamp_: number | null = null;

  verbose_: boolean = true;

  bidsByPlayerID_: { [key: string]: Rules.Bid } = {};
  resolvesByPlayerID_: { [key: string]: Rules.ResolutionSelection } = {};
  rollSelectionsByPlayerID_: { [key: string]: Rules.RerollSelection } = {};

  planningDataByPlayerID_: {
    [playerID: string]: {
      [tributeID: string]: TributeLogic.TributeLogicPlanningData;
    };
  } = {};
  resolutionDataByPlayerID_: {
    [playerID: string]: {
      [tributeID: string]: TributeLogic.TributeLogicResolutionData;
    };
  } = {};
  productionDataByPlayerID_: {
    [playerID: string]: {
      [tributeID: string]: TributeLogic.TributeLogicProductionData;
    };
  } = {};

  rollsByPlayerID_: { [key: string]: number[] | null } = {};
  rerollRound_: number = 0;

  events_: GameEvent[] = [];
  sequenceID_: number = 0;
  fetchedSequenceID_: number = 0;

  cardDefinitions_: CardDefWithIndex[] = [];
  tributeCardDefinitions_: TributeDefWithIndex[] = [];

  totalThinkingMillisByPlayerID_: { [key: string]: number } = {};
  phaseThinkingMillisByPlayerID_: { [key: string]: number } = {};
  phaseStartTimestamp_: number = Date.now();
  phaseTimingData_: Array<any> = [];

  boardSize_: number;
  tributeRowSize_: number;
  turnsPerAge_: number;

  gameUpdateTimestamp_: number;

  constructor(id: string, players: Player[], options: GameOptions) {
    this.id_ = id;
    this.players_ = players;
    this.options_ = options;

    this.phaseStartTimestamp_ = Date.now();
    this.gameUpdateTimestamp_ = Date.now();

    this.boardSize_ = this.players_.length + 1;
    this.tributeRowSize_ = 3;
    this.turnsPerAge_ = GameConstants.TURNS_PER_AGE;
  }

  getID(): string {
    return this.id_;
  }
  getSequenceID(): number {
    return this.sequenceID_;
  }
  getFetchedSequenceID(): number {
    return this.fetchedSequenceID_;
  }
  getPlayers(): Player[] {
    return this.players_;
  }
  getPlayerByID(userID: string): Player | null {
    return (
      this.players_.find((player) => {
        return player.getID() === userID;
      }) || null
    );
  }
  getPlayerByIDEnforcing(userID: string): Player {
    return nullthrows(this.getPlayerByID(userID));
  }
  isFinished(): boolean {
    return this.gameEndTimestamp_ !== null;
  }
  getStartTimestamp(): number | null {
    return this.gameStartTimestamp_;
  }
  getAge(): number {
    return this.age_;
  }
  getTurn(): number {
    return this.turn_;
  }
  getPhase(): string {
    return this.phase_;
  }
  spawnCard(card_def: CardDefWithIndex): Rules.CardWithID {
    const card = {
      ...card_def,
      id: `${this.lastCardID_}`,
    };
    this.lastCardID_ += 1;
    this.cardsByID_[card.id] = card;
    return card;
  }
  spawnTributeCard(card_def: TributeDefWithIndex): Rules.TributeCardWithID {
    const tributeCard = {
      ...card_def,
      id: `${this.lastCardID_}`,
    };
    this.lastCardID_ += 1;
    this.tributeCardsByID_[tributeCard.id] = tributeCard;
    return tributeCard;
  }

  bumpSequenceID(): void {
    this.sequenceID_ += 1;
  }

  setUpGame(
    coreCardDefinitions: CardDef[],
    coreTributeCardDefinitions: TributeDef[],
    modules: ModuleDef[],
  ): void {
    this.gameStartTimestamp_ = Date.now();
    this.players_.forEach((player) => {
      this.totalThinkingMillisByPlayerID_[player.getID()] = 0;
    });

    const { cardDefinitions, tributeCardDefinitions } = mergeModuleCards(
      coreCardDefinitions,
      coreTributeCardDefinitions,
      modules,
    );
    modules.forEach((module) => {
      module.gameOptionsToAdd.forEach((option) => {
        this.options_[option] = true;
      });
    });

    this.cardDefinitions_ = cardDefinitions.map((card_def, index) => {
      return {
        ...card_def,
        index,
      };
    });
    this.tributeCardDefinitions_ = tributeCardDefinitions.map(
      (card_def, index) => {
        return {
          ...card_def,
          index,
        };
      },
    );

    if (this.options_.biggerTradeRow) {
      this.boardSize_ += 1;
    }

    // TODO SET_UP_GAME event recording the definitions?

    const startingCardDefs: { [key: string]: Array<CardDefWithIndex> } = {};

    this.players_.forEach((player) => {
      startingCardDefs[player.getID()] = DEBUG_STARTING_CARDS.map((name) => {
        return this.cardDefinitions_.find((card) => card.name === name);
      }).filter(nonNull);
    });

    let validLeaders = this.cardDefinitions_.filter(
      (card_def) => card_def.affinity === CardAffinity.Leader,
    );
    if (
      this.options_.randomLeader &&
      validLeaders.length >= this.players_.length
    ) {
      validLeaders = shuffle(validLeaders.slice(1));
      this.players_.forEach((player) => {
        startingCardDefs[player.getID()].push(validLeaders[0]);
        validLeaders = validLeaders.slice(1);
      });
    } else {
      this.players_.forEach((player) => {
        startingCardDefs[player.getID()].push(validLeaders[0]);
      });
    }

    this.players_.forEach((player) => {
      startingCardDefs[player.getID()].forEach((def) => {
        const card = this.spawnCard(def);

        let context = Rules.makeContext(
          player,
          this.turn_,
          this.age_,
          this.options_,
        );

        context = Rules.handleGainCard(context, card);

        this.resolveContext(context);

        player.cards.push(card);

        // TODO: a START_CARD event might be better for the log
        this.addEventHelper({
          type: EventTypes.GAIN_CARD,
          payload: {
            userID: player.getID(),
            cardID: card.id,
            cost: context.spent_delta,
            ...context.production_delta,
          },
        });
      });
    });

    this.tributeDeck_.addCardsToTop(
      this.tributeCardDefinitions_.map((card_def) =>
        this.spawnTributeCard(card_def),
      ),
    );
    this.tributeDeck_.shuffle();

    this.setUpAge(1);
  }
  setUpAge(age: number): void {
    this.age_ = age;
    // if (this.options_.tutorial) {
    //   Tutorial.cardDefIDsByAge[age - 1].forEach((def_id) => {
    //     let card_def = this.cardDefinitions_[def_id];
    //     invariant(card_def, 'could not find card def for index "%s"', def_id);
    //     this.deck_.addCardsToTop([this.spawnCard(card_def)]);
    //   });
    // } else {
    invariant(this.deck_.count() === 0, 'deck not empty');
    this.cardDefinitions_.forEach((card_def) => {
      if (card_def.affinity !== CardAffinity.Basic && card_def.age === age) {
        this.deck_.addCardsToTop([this.spawnCard(card_def)]);
      }
    });
    this.deck_.shuffle();
    // }

    this.basicPile_ = [];
    this.cardDefinitions_.forEach((card_def) => {
      if (card_def.affinity === CardAffinity.Basic && card_def.age === age) {
        this.basicPile_.push(this.spawnCard(card_def));
      }
    });

    TEST_TRIBUTES.forEach((tribute_name) => {
      var test_tribute_def = this.tributeCardDefinitions_.find((card_def) => {
        return card_def.name === tribute_name;
      });
      invariant(test_tribute_def, 'Test tribute %s not found', tribute_name);
      this.tributeDeck_.addCardsToTop([
        this.spawnTributeCard(test_tribute_def),
      ]);
    });

    this.addEventHelper({
      type: EventTypes.SET_UP_AGE,
      payload: {
        age: this.age_,
      },
    });

    this.setUpTurn(1);
  }

  finishAge(): void {
    this.table_.forEach((card) => {
      this.addEventHelper({
        type: EventTypes.SLIDE_CARD,
        payload: {
          cardID: card.id,
        },
      });
    });
    this.discard_ = this.discard_.concat(this.table_);
    this.table_ = [];
    this.discard_ = this.discard_.concat(this.deck_.drawAll());

    this.tributeRow_.forEach((card) => {
      this.addEventHelper({
        type: EventTypes.DISCARD_TRIBUTE,
        payload: {
          cardID: card.id,
        },
      });
      this.tributeDiscard_.push(card);
    });
    this.tributeRow_ = [];

    this.players_.forEach((player) => {});
  }

  setUpTurn(turn: number): void {
    this.turn_ = turn;

    this.addEventHelper({
      type: EventTypes.SET_UP_TURN,
      payload: {
        turn: this.turn_,
      },
    });

    this.setUpPhase(Phases.SETUP);
    this.handleSetupPhase();
    this.finishPhase();

    this.setUpPhase(Phases.PRODUCTION);
    this.handleProductionPhase();
    this.finishPhase();

    this.setUpPhase(Phases.PLANNING);

    this.rollsByPlayerID_ = {};
    if (this.options_.prerollDice) {
      let dice_count = this.age_;
      this.players_.forEach((player) => {
        this.rollsByPlayerID_[player.getID()] = _.times(dice_count, roll_die);
      });
    }

    this.rerollRound_ = 0;
  }

  finishTurn(): void {
    // cleanup phase
    this.players_.forEach((player) => {
      player.bid = null;
      player.selectedCard = null;
    });

    this.planningDataByPlayerID_ = {};
    this.resolutionDataByPlayerID_ = {};
    this.bidsByPlayerID_ = {};
    this.resolvesByPlayerID_ = {};
    this.rollSelectionsByPlayerID_ = {};
  }

  setUpPhase(phase: Phase): void {
    this.phase_ = phase;

    this.phaseStartTimestamp_ = Date.now();
    this.phaseThinkingMillisByPlayerID_ = {};
    this.players_.forEach((player) => {
      this.phaseThinkingMillisByPlayerID_[player.getID()] = 0;
    });

    this.addEventHelper({
      type: EventTypes.SET_UP_PHASE,
      payload: {
        age: this.age_,
        turn: this.turn_,
        phase: this.phase_,
      },
    });
  }

  finishPhase(): void {
    this.players_.forEach((player) => {
      this.totalThinkingMillisByPlayerID_[player.getID()] +=
        this.phaseThinkingMillisByPlayerID_[player.getID()];
    });

    if (this.phaseStartTimestamp_) {
      var elapsed = Date.now() - nullthrows(this.phaseStartTimestamp_);
      var timingStats = {
        age: this.age_,
        turn: this.turn_,
        phase: this.phase_,
        elapsed_phase_time: elapsed,
        elapsed_phase_time_by_player_id: this.phaseThinkingMillisByPlayerID_,
        elapsed_game_time: Date.now() - nullthrows(this.gameStartTimestamp_),
      };
      this.phaseTimingData_.push(timingStats);
      this.addEventHelper({
        type: EventTypes.END_OF_PHASE,
        payload: timingStats,
      });
    }
  }

  addEventHelper(event: GameEventData): void {
    this.events_.push({
      ...event,
      timestamp: Date.now(),
      age: this.age_,
      turn: this.turn_,
      phase: this.phase_,
    });
  }

  handleSelectionsIfNecessary_(): void {
    var phase = this.phase_;
    if (phase === Phases.PLANNING) {
      const all_ready = this.players_.every((player) => {
        return !!this.bidsByPlayerID_[player.getID()];
      });
      if (!all_ready) {
        return;
      }
      this.handlePlanningSelections();
    } else if (phase === Phases.WAR) {
      const ready =
        this.rerollRound_ === 0 ||
        this.players_.some((player) => {
          return (
            this.rollSelectionsByPlayerID_[player.getID()]?.rerollType ===
            'reroll'
          );
        }) ||
        this.players_.every((player) => {
          return !!this.rollSelectionsByPlayerID_[player.getID()];
        });
      if (!ready) {
        return;
      }
      const warFinished = this.handleWarSelections();
      if (!warFinished) {
        return;
      }
    } else if (phase === Phases.RESOLUTION) {
      const all_ready = this.players_.every((player) => {
        return !!this.resolvesByPlayerID_[player.getID()];
      });
      if (!all_ready) {
        return;
      }

      this.handleResolutionSelections();
    } else if (phase === Phases.TRIBUTE) {
      this.handleTributeSelections();
    } else {
      invariant(false, 'unknown phase %s', phase);
    }

    this.finishPhase();
    var new_phase = nextPhase(this.phase_);
    if (new_phase != _.first(PhaseOrder)) {
      this.setUpPhase(new_phase);
      return this.handleSelectionsIfNecessary_();
    }

    this.finishTurn();
    var new_turn = this.turn_ + 1;
    if (new_turn <= this.turnsPerAge_) {
      this.setUpTurn(new_turn);
      return this.handleSelectionsIfNecessary_();
    }

    this.finishAge();
    var new_age = this.age_ + 1;
    if (new_age <= Game.AGES_PER_GAME) {
      this.setUpAge(new_age);
      return this.handleSelectionsIfNecessary_();
    } else {
      this.finishGame();
    }
  }

  resolveContext(context: Rules.Context): void {
    Rules.resolveContextCounters(context);
  }

  // round is 0 indexed
  getRollForPlayerID(player_id: string, round: number): Array<number> {
    // if (this.options_.tutorial) {
    //   let turn = (this.age_ - 1) * Game.TURNS_PER_AGE + this.turn_ - 1;

    //   let rollsByRound = Tutorial.rollsByRoundByTurn[turn];
    //   if (rollsByRound) {
    //     let rolls = rollsByRound[round];
    //     if (rolls) {
    //       let player_index = _.findIndex(this.players_, (player) => {
    //         return player.getID() === player_id;
    //       });
    //       invariant(rolls.length > player_index, 'bad tutorial rolls');
    //       return rolls[player_index];
    //     }
    //   }
    // }

    if (round === 0) {
      const prerolls = this.rollsByPlayerID_[player_id];
      if (prerolls) {
        return prerolls;
      }
    }

    return _.times(this.age_, roll_die);
  }

  handlePlanningSelections(): void {
    // reveal bids and pay military
    this.players_.forEach((player) => {
      const bid = this.bidsByPlayerID_[player.getID()];

      let context = Rules.makeContext(
        player,
        this.turn_,
        this.age_,
        this.options_,
      );

      context = Rules.handleBid(context, bid);
      player.bid = bid;

      this.resolveContext(context);

      this.addEventHelper({
        type: EventTypes.BID,
        payload: {
          userID: player.getID(),
          cardID: this.table_[bid.tradeRowIndex].id,
          military: bid.military,
          spentMilitary: context.spent_delta.military,
        },
      });
    }, this);
  }

  computeClashResults(
    card: Rules.CardWithID,
    playerInfos: PlayerWarInfo[],
    playerIDsWhoRolled: Set<string>,
  ): ClashResults {
    invariant(
      playerInfos.length >= 1,
      'must have at least one player per conflicted card',
    );

    let rounds: ConflictRoundInfo[] = [];
    let currentPlayerInfos = [...playerInfos];
    while (true) {
      let bestInfos: PlayerWarInfo[] = [];
      let resultsByPlayerID: ConflictRoundInfo = {};
      currentPlayerInfos.forEach((playerInfo) => {
        const result = {
          playerID: playerInfo.playerID,
          rolls: playerInfo.rolls,
          bonus: playerInfo.totalBonus,
          total: playerInfo.totalFinal,
          didRoll: playerIDsWhoRolled.has(playerInfo.playerID),
        };
        resultsByPlayerID[playerInfo.playerID] = result;
        var best = _.first(bestInfos);
        if (!best || playerInfo.totalFinal > best.totalFinal) {
          bestInfos = [playerInfo];
        } else if (best && playerInfo.totalFinal === best.totalFinal) {
          bestInfos.push(playerInfo);
        }
      });
      rounds.push({ ...resultsByPlayerID });
      invariant(bestInfos.length >= 1, 'must have at least one best result');

      currentPlayerInfos = bestInfos;
      if (currentPlayerInfos.length !== 1) {
        playerIDsWhoRolled = new Set<string>();
        // reroll to break ties
        currentPlayerInfos.forEach((playerInfo) => {
          playerInfo.rolls = times(this.age_, roll_die);
          playerInfo.totalFinal =
            Utility.sum(playerInfo.rolls, _.identity) + playerInfo.totalBonus;
          playerIDsWhoRolled.add(playerInfo.playerID);
        });
      } else {
        //done
        break;
      }
    }

    invariant(currentPlayerInfos.length === 1, 'must have exactly one winner');
    const winningPlayerID = currentPlayerInfos[0].playerID;

    return {
      cardID: card.id,
      rounds: rounds,
      winningPlayerID: winningPlayerID,
    };
  }

  // returns true if the war phase is complete
  handleWarSelections(): boolean {
    const playerIDsWhoRolled = new Set<string>();

    if (this.rerollRound_ === 0) {
      this.players_.forEach((player) => {
        const playerID = player.getID();
        if (!this.rollsByPlayerID_[playerID]) {
          this.rollsByPlayerID_[playerID] = times(this.age_, roll_die);
        }
        playerIDsWhoRolled.add(playerID);
      });
    }

    // handle rerolls
    this.players_.forEach((player) => {
      const playerID = player.getID();
      const selection = this.rollSelectionsByPlayerID_[playerID];
      const rerollType = selection?.rerollType || 'na';
      if (rerollType === 'reroll') {
        this.rollsByPlayerID_[playerID] = times(this.age_, roll_die);

        // XXX via context?
        invariant(player.counters.rerollTokens > 0, 'no reroll tokens');
        player.counters.rerollTokens -= 1;
        this.addEventHelper({
          type: EventTypes.USE_REROLL,
          payload: {
            userID: playerID,
            rolls: this.rollsByPlayerID_[playerID] || [],
          },
        });
        playerIDsWhoRolled.add(playerID);
      } else if (rerollType === 'decline') {
        this.addEventHelper({
          type: EventTypes.DECLINE_REROLL,
          payload: {
            userID: playerID,
          },
        });
      } else {
        invariant(rerollType === 'na', 'invalid reroll type');
      }
    });
    this.rollSelectionsByPlayerID_ = {};

    // prepare data
    const playerWarInfosByTradeRowIndex: Map<number, PlayerWarInfo[]> =
      new Map();
    this.players_.forEach((player) => {
      const playerID = player.getID();
      const bid = player.bid;
      invariant(!!bid, 'must have planning selection');
      const totalBonus = bid.military;
      const rolls = nullthrows(this.rollsByPlayerID_[playerID]);

      const v = playerWarInfosByTradeRowIndex.get(bid.tradeRowIndex) || [];
      v.push({
        playerID,
        rolls,
        totalBonus,
        totalFinal: Utility.sum(rolls, _.identity) + totalBonus,
        hasReroll: player.counters.rerollTokens > 0,
      });
      playerWarInfosByTradeRowIndex.set(bid.tradeRowIndex, v);
    });

    // if we rolled, check to see if someone can reroll
    if (playerIDsWhoRolled.size > 0) {
      const playerIDsWithRerolls = new Set<string>();
      playerWarInfosByTradeRowIndex.forEach((playerBidInfos, tradeRowIndex) => {
        const maxBidInfo = maxBy(playerBidInfos, (x) => x.totalFinal)!;
        const winningBidInfos = playerBidInfos.filter(
          (x) => x.totalFinal === maxBidInfo.totalFinal,
        );
        playerBidInfos.forEach((x) => {
          // reroll if, have token, and didn't win
          if (!x.hasReroll) {
            return;
          }
          if (
            x.totalFinal < maxBidInfo.totalFinal ||
            winningBidInfos.length > 1
          ) {
            playerIDsWithRerolls.add(x.playerID);
          }
        });
      });
      if (playerIDsWithRerolls.size > 0) {
        // wait for reroll responses
        this.rerollRound_++;
        this.rollSelectionsByPlayerID_ = {};
        this.players_.forEach((player) => {
          if (!playerIDsWithRerolls.has(player.getID())) {
            this.rollSelectionsByPlayerID_[player.getID()] = {
              rerollType: 'na',
              rerollRound: this.rerollRound_,
            };
          }
        });

        // log conflict results and players with rerolls
        playerWarInfosByTradeRowIndex.forEach(
          (playerBidInfos, tradeRowIndex) => {
            if (
              playerBidInfos.every((x) => !playerIDsWhoRolled.has(x.playerID))
            ) {
              return;
            }
            const card = this.table_[tradeRowIndex];
            const round = Object.fromEntries(
              playerBidInfos.map((playerBidInfo) => {
                return [
                  playerBidInfo.playerID,
                  {
                    playerID: playerBidInfo.playerID,
                    rolls: playerBidInfo.rolls,
                    bonus: playerBidInfo.totalBonus,
                    total: playerBidInfo.totalFinal,
                    didRoll: playerIDsWhoRolled.has(playerBidInfo.playerID),
                  },
                ];
              }),
            );
            this.addEventHelper({
              type: EventTypes.CONFLICT_RESULTS,
              payload: {
                cardID: card.id,
                rounds: [round],
                playerIDsWithRerolls: Array.from(playerIDsWithRerolls).filter(
                  (playerID) =>
                    playerBidInfos.some((y) => y.playerID === playerID),
                ),
              },
            });
          },
        );
        return false;
      }
    }

    // resolve ties and calculate war results
    const warResultsByPlayerID = new Map<string, TributeLogic.WarResults>();
    playerWarInfosByTradeRowIndex.forEach((playerBidInfos, tradeRowIndex) => {
      const card = this.table_[tradeRowIndex];

      const clashResults = this.computeClashResults(
        card,
        playerBidInfos,
        playerIDsWhoRolled,
      );

      const winningPlayerID = clashResults.winningPlayerID;
      const winningPlayer = this.getPlayerByIDEnforcing(winningPlayerID);
      winningPlayer.selectedCard = card;
      playerBidInfos.forEach((playerBidInfo) => {
        warResultsByPlayerID.set(playerBidInfo.playerID, {
          winningPlayerID,
          rounds: clashResults.rounds,
          participantPlayerIDs: playerBidInfos.map(
            (playerBidInfo) => playerBidInfo.playerID,
          ),
        });
      });

      this.addEventHelper({
        type: EventTypes.CONFLICT_RESULTS,
        payload: clashResults,
      });
    });

    const contextByPlayerID = new Map<string, Rules.Context>(
      this.players_.map((player) => [
        player.getID(),
        Rules.makeContext(player, this.turn_, this.age_, this.options_),
      ]),
    );

    if (this.options_.rerollTokens) {
      playerWarInfosByTradeRowIndex.forEach((playerInfos, tradeRowIndex) => {
        if (playerInfos.length < 2) {
          return;
        }

        const winningPlayerInfo = maxBy(playerInfos, (x) => x.totalFinal)!;
        const winningBonus = winningPlayerInfo.totalBonus;

        playerInfos.forEach((playerInfo) => {
          const context = contextByPlayerID.get(playerInfo.playerID)!;
          if (
            playerInfo !== winningPlayerInfo &&
            playerInfo.totalBonus >= winningBonus &&
            context.player.counters.rerollTokens !== 1
          ) {
            const context = contextByPlayerID.get(playerInfo.playerID)!;
            context.production_delta.rerollTokens += 1;

            this.addEventHelper({
              type: EventTypes.GAIN_REROLL,
              payload: {
                userID: playerInfo.playerID,
              },
            });
          }
        });
      });
    }

    if (this.options_.warTokens) {
      playerWarInfosByTradeRowIndex.forEach((playerInfos, tradeRowIndex) => {
        if (playerInfos.length < 2) {
          return;
        }
        playerInfos.forEach((playerInfo) => {
          let context = contextByPlayerID.get(playerInfo.playerID)!;
          context.production_delta.warTokens += 1;
          context = Rules.handleTokenGain(context, Token.War);
          contextByPlayerID.set(playerInfo.playerID, context);

          this.addEventHelper({
            type: EventTypes.EARN_TOKEN,
            payload: {
              userID: playerInfo.playerID,
              token: Token.War,
              triggeredCards: {
                cardIDs: context.triggeredCardIDs,
                ...context.triggerDelta,
              },
            },
          });
        });
      });
    }

    this.players_.forEach((player) => {
      this.planningDataByPlayerID_[player.getID()] = {};
      this.tributeRow_.forEach((tribute) => {
        var tributeLogic = TributeLogic.getTributeLogic(tribute);
        this.planningDataByPlayerID_[player.getID()][tribute.id] =
          tributeLogic?.planningDataFunction(
            contextByPlayerID.get(player.getID())!,
            {
              results: nullthrows(warResultsByPlayerID.get(player.getID())),
              // @ts-ignore
              players: this.players_,
            },
          ) || null;
      });

      const context = contextByPlayerID.get(player.getID())!;
      this.resolveContext(context);
    });
    return true;
  }

  handleProductionPhase(): void {
    // Production phase
    this.players_.forEach((player) => {
      let context = Rules.makeContext(
        player,
        this.turn_,
        this.age_,
        this.options_,
      );
      context = Rules.handleProduction(context);

      this.productionDataByPlayerID_[player.getID()] = {};

      this.resolveContext(context);

      this.addEventHelper({
        type: EventTypes.PRODUCTION,
        payload: {
          userID: player.getID(),
          ...context.production_delta,
        },
      });

      this.productionDataByPlayerID_[player.getID()] = {};
      this.tributeRow_.forEach((tribute) => {
        const tributeLogic = TributeLogic.getTributeLogic(tribute);
        this.productionDataByPlayerID_[player.getID()][tribute.id] =
          tributeLogic?.productionDataFunction(context);
      });
    });
  }

  handleResolutionSelections(): void {
    const cardToGainByPlayerID = new Map<string, Rules.CardWithID>();
    const contextByPlayerID = new Map<string, Rules.Context>();
    const tradeRowIDsToRemove = new Set<string>();
    this.players_.forEach((player) => {
      const player_id = player.getID();
      const bid = player.bid!;
      const resolve = this.resolvesByPlayerID_[player_id];

      const draftedCard = this.table_[bid.tradeRowIndex];
      const cardToGain = nullthrows(this.cardsByID_[resolve.cardIDToGain]);

      // XXX is this necessary?
      player.selectedCard = cardToGain;

      let context = Rules.makeContext(
        player,
        this.turn_,
        this.age_,
        this.options_,
      );
      context = Rules.handleResolutionSelection(
        context,
        draftedCard,
        cardToGain,
      );

      contextByPlayerID.set(player_id, context);
      cardToGainByPlayerID.set(player_id, cardToGain);

      let gainedCard = null;
      if (this.table_.find((card) => card.id === cardToGain.id)) {
        gainedCard = cardToGain;
        tradeRowIDsToRemove.add(cardToGain.id);
      } else if (this.basicPile_.find((card) => card.id === cardToGain.id)) {
        gainedCard = this.spawnCard(cardToGain);
      } else {
        invariant(false, 'card to gain not in trade row or basic pile');
      }

      player.cards.push(gainedCard);
      Rules.handleGainCard(context, gainedCard);

      this.resolveContext(context);

      this.addEventHelper({
        type: EventTypes.GAIN_CARD,
        payload: {
          userID: player_id,
          cardID: gainedCard.id,
          cost: context.spent_delta,
          ...context.card_delta,
          triggeredCards:
            context.triggeredCardIDs.length > 0
              ? {
                  cardIDs: context.triggeredCardIDs,
                  ...context.triggerDelta,
                }
              : undefined,
        },
      });

      this.resolutionDataByPlayerID_[player_id] = {};

      this.tributeRow_.forEach((tribute) => {
        const tributeLogic = TributeLogic.getTributeLogic(tribute);
        this.resolutionDataByPlayerID_[player_id][tribute.id] =
          tributeLogic?.resolutionDataFunction(
            context,
            this.table_,
            cardToGain,
          ) || null;
      });
    });

    if (this.options_.tradeRowSlide) {
      if (this.table_.length > 0) {
        let right_card_id = this.table_[this.table_.length - 1].id;
        if (!tradeRowIDsToRemove.has(right_card_id)) {
          tradeRowIDsToRemove.add(right_card_id);
          this.addEventHelper({
            type: EventTypes.SLIDE_CARD,
            payload: {
              cardID: right_card_id,
            },
          });
        }
      }
    }
    if (this.options_.wipeTradeRow) {
      for (const card of this.table_) {
        if (!tradeRowIDsToRemove.has(card.id)) {
          tradeRowIDsToRemove.add(card.id);
          this.addEventHelper({
            type: EventTypes.SLIDE_CARD,
            payload: {
              cardID: card.id,
            },
          });
        }
      }
    }

    this.table_ = this.table_.filter((card) => {
      return !tradeRowIDsToRemove.has(card.id);
    });
  }

  handleTributeSelections(): void {
    var tributeIDsToRemove = new Set<string>();
    this.players_.forEach((player) => {
      const conflictResultsEvent = nullthrows(
        findReverse(this.events_, (event) => {
          return (
            event.age === this.age_ &&
            event.turn === this.turn_ &&
            event.type === EventTypes.CONFLICT_RESULTS &&
            event.payload.rounds[0][player.getID()] != null
          );
        }),
      );
      invariant(
        conflictResultsEvent.type === EventTypes.CONFLICT_RESULTS,
        'ts',
      );
      // handle completed tributes
      var completed_tribute_context = Rules.handleTributeGain(
        Rules.makeContext(player, this.turn_, this.age_, this.options_),
        this.tributeRow_,
        this.planningDataByPlayerID_[player.getID()] || {},
        this.resolutionDataByPlayerID_[player.getID()] || {},
        this.productionDataByPlayerID_[player.getID()] || {},
        this.players_,
        conflictResultsEvent,
      );
      completed_tribute_context.completedTributeIDs.forEach((tribute_id) => {
        tributeIDsToRemove.add(tribute_id);
      });

      this.resolveContext(completed_tribute_context);

      if (completed_tribute_context.completedTributeIDs.length > 0) {
        const triggeredCardsCounters = {
          ...completed_tribute_context.production_delta,
        };
        triggeredCardsCounters.favor -= completed_tribute_context.tribute_favor;
        this.addEventHelper({
          type: EventTypes.GAIN_TRIBUTES,
          payload: {
            userID: player.getID(),
            favor: completed_tribute_context.tribute_favor,
            tributeIDs: completed_tribute_context.completedTributeIDs,
            triggeredCards: {
              cardIDs: completed_tribute_context.triggeredCardIDs,
              ...triggeredCardsCounters,
            },
          },
        });
      }
    });
    var partition = _.partition(this.tributeRow_, (card) => {
      return tributeIDsToRemove.has(card.id);
    });
    this.tributeDiscard_ = this.tributeDiscard_.concat(partition[0]);
    this.tributeRow_ = partition[1];
  }

  handleSetupPhase(): void {
    DEBUG_TRADE_ROW_CARDS.forEach((name) => {
      var existing = this.table_.find((c) => {
        return c.name === name;
      });
      if (!existing) {
        var def = this.cardDefinitions_.find((c) => {
          return c.name === name;
        });
        if (def) {
          this.table_.push(this.spawnCard(def));
        }
      }
    });

    while (this.table_.length < this.boardSize_) {
      const new_card = this.deck_.drawOne();
      if (!new_card) {
        break;
      }

      this.table_.unshift(new_card);

      this.addEventHelper({
        type: EventTypes.DEAL_CARD,
        payload: {
          cardID: new_card.id,
        },
      });
    }

    while (this.tributeRow_.length < this.tributeRowSize_) {
      if (this.tributeDeck_.count() === 0) {
        this.tributeDeck_.addCardsToTop(this.tributeDiscard_);
        this.tributeDiscard_ = [];
        this.tributeDeck_.shuffle();
        this.addEventHelper({
          type: EventTypes.TRIBUTES_RESHUFFLED,
          payload: {
            turn: this.turn_,
          },
        });
      }
      var new_tribute = this.tributeDeck_.drawOne();
      if (new_tribute) {
        this.tributeRow_.unshift(new_tribute);

        this.addEventHelper({
          type: EventTypes.DEAL_TRIBUTE,
          payload: {
            cardID: new_tribute.id,
          },
        });
      } else {
        break;
      }
    }
  }

  finishGame(): void {
    this.gameEndTimestamp_ = Date.now();

    this.players_.forEach((player) => {
      let context = Rules.makeContext(
        player,
        this.turn_,
        this.age_,
        this.options_,
      );
      context = Rules.handleEndOfGameEffects(context);

      this.resolveContext(context);

      this.addEventHelper({
        type: EventTypes.END_GAME_EFFECTS,
        payload: {
          userID: player.getID(),
          triggeredCards: {
            cardIDs: context.triggeredCardIDs,
            ...context.production_delta,
          },
        },
      });
    });

    this.players_.forEach((player) => {
      let context = Rules.makeContext(
        player,
        this.turn_,
        this.age_,
        this.options_,
      );
      context = Rules.handleEndGameScoring(context);

      this.resolveContext(context);

      this.addEventHelper({
        type: EventTypes.END_GAME_SCORING,
        payload: {
          userID: player.getID(),
          ...context.production_delta,
          cost: context.spent_delta,
        },
      });
    });
  }

  validateBid(player: Player, bid: Rules.Bid): void {
    Rules.validateBid(player, bid, this._getRulesGameInfo());
  }

  validateResolve(player: Player, resolve: Rules.ResolutionSelection): void {
    Rules.validateResolve(player, resolve, this._getRulesGameInfo());
  }

  validateRollSelection(
    player: Player,
    rollSelection: Rules.RerollSelection,
  ): Rules.RerollSelection {
    if (rollSelection.rerollRound !== this.rerollRound_) {
      throw new Error('reroll count does not match');
    }
    if (
      rollSelection.rerollType !== 'decline' &&
      rollSelection.rerollType !== 'reroll'
    ) {
      throw new Error('invalid reroll type');
    }
    return {
      rerollType: rollSelection.rerollType,
      rerollRound: rollSelection.rerollRound,
    };
  }

  private _getRulesGameInfo(): Rules.GameInfo {
    return {
      turn: this.turn_,
      phase: this.phase_,
      age: this.age_,
      basicPile: <Rules.CardWithID[]>this.basicPile_,
      cardsByID: this.cardsByID_,
      options: this.options_,
      tradeRow: this.table_,
    };
  }

  addAction(userID: string, action: any): void {
    var player = this.getPlayerByID(userID);
    if (!player) {
      throw new Error('invalid userID ' + userID);
    }
    this.debug_log('add action', JSON.stringify(action));
    switch (action.type) {
      case ActionTypes.BID: {
        if (this.bidsByPlayerID_[userID]) {
          throw new Error('bid already submitted');
        }
        const bid = action.payload.bid;
        this.validateBid(player, bid);

        this.bidsByPlayerID_[userID] = bid;
        var thinkingTime = Date.now() - this.phaseStartTimestamp_;
        this.phaseThinkingMillisByPlayerID_[userID] = thinkingTime;

        this.handleSelectionsIfNecessary_();
        this.gameUpdateTimestamp_ = Date.now();
        break;
      }
      case ActionTypes.REROLL: {
        if (this.rollSelectionsByPlayerID_[userID]) {
          throw new Error('reroll already submitted');
        }
        const rollSelection = action.payload.rollSelection;
        this.rollSelectionsByPlayerID_[userID] = this.validateRollSelection(
          player,
          rollSelection,
        );

        this.handleSelectionsIfNecessary_();
        this.gameUpdateTimestamp_ = Date.now();
        break;
      }
      case ActionTypes.RESOLVE: {
        if (this.resolvesByPlayerID_[userID]) {
          throw new Error('resolve already submitted');
        }
        const resolve = action.payload.resolve;
        this.validateResolve(player, resolve);

        this.resolvesByPlayerID_[userID] = resolve;
        var thinkingTime = Date.now() - this.phaseStartTimestamp_;
        this.phaseThinkingMillisByPlayerID_[userID] = thinkingTime;

        this.handleSelectionsIfNecessary_();
        this.gameUpdateTimestamp_ = Date.now();
        break;
      }
      default:
        throw new Error('unknown action type: ' + action.type);
    }
    this.bumpSequenceID();
  }

  private _serializeCardsByID(cards: {
    [key: string]: Rules.CardWithID | Rules.TributeCardWithID;
  }): [string, number][] {
    return Object.entries(cards).map(([id, card]) => {
      return [id, card.index];
    });
  }

  toJSON(): any {
    return {
      version: GAME_VERSION,
      id: this.id_,
      sequenceID: this.sequenceID_,
      players: this.players_.map((player) => player.toJSON()),
      options: this.options_,
      bidsByPlayerID: this.bidsByPlayerID_,
      resolvesByPlayerID: this.resolvesByPlayerID_,
      rollSelectionsByPlayerID: this.rollSelectionsByPlayerID_,
      planningDataByPlayerID: this.planningDataByPlayerID_,
      resolutionDataByPlayerID: this.resolutionDataByPlayerID_,
      productionDataByPlayerID: this.productionDataByPlayerID_,
      rollsByPlayerID: this.rollsByPlayerID_,
      rerollRound: this.rerollRound_,

      tableIDs: this.table_.map((card) => card.id),
      tributeRowIDs: this.tributeRow_.map((card) => card.id),
      basicPileIDs: this.basicPile_.map((card) => card.id),
      discardIDs: this.discard_.map((card) => card.id),
      tributeDiscardIDs: this.tributeDiscard_.map((card) => card.id),
      deck: this.deck_.serialize(),
      tributeDeck: this.tributeDeck_.serialize(),

      cardDefinitions: this.cardDefinitions_,
      tributeCardDefinitions: this.tributeCardDefinitions_,

      age: this.age_,
      turn: this.turn_,
      phase: this.phase_,
      events: this.events_,
      cardsByID: this._serializeCardsByID(this.cardsByID_),
      tributeCardsByID: this._serializeCardsByID(this.tributeCardsByID_),
      lastCardID: this.lastCardID_,

      gameStartTimestamp: this.gameStartTimestamp_,
      gameEndTimestamp: this.gameEndTimestamp_,
      gameUpdateTimestamp: this.gameUpdateTimestamp_,

      phaseStartTimestamp: this.phaseStartTimestamp_,
      totalThinkingMillisByPlayerID: this.totalThinkingMillisByPlayerID_,
      phaseThinkingMillisByPlayerID: this.phaseThinkingMillisByPlayerID_,
      phaseTimingData: this.phaseTimingData_,

      boardSize: this.boardSize_,
      tributeRowSize: this.tributeRowSize_,
      turnsPerAge: this.turnsPerAge_,
    };
  }
  static fromJSON(json: any): Game {
    json = migrateGameJSON(json);
    if (json.version !== GAME_VERSION) {
      throw new Error(
        `version mismatch: expected ${GAME_VERSION}, got ${json.version}`,
      );
    }

    let ret: Game = new Game(json.id, json.players, json.options);
    ret.id_ = json.id;
    ret.options_ = json.options;

    ret.cardDefinitions_ = json.cardDefinitions;
    ret.tributeCardDefinitions_ = json.tributeCardDefinitions;

    ret.lastCardID_ = json.lastCardID;
    ret.cardsByID_ = Object.fromEntries(
      json.cardsByID.map(([id, index]: [string, number]) => {
        return [
          id,
          {
            id,
            ...ret.cardDefinitions_[index],
          },
        ];
      }),
    );
    ret.tributeCardsByID_ = Object.fromEntries(
      json.tributeCardsByID.map(([id, index]: [string, number]) => {
        return [
          id,
          {
            id,
            ...ret.tributeCardDefinitions_[index],
          },
        ];
      }),
    );
    ret.deck_ = Deck.deserialize(json.deck, ret.cardsByID_);
    ret.tributeDeck_ = Deck.deserialize(
      json.tributeDeck,
      ret.tributeCardsByID_,
    );
    ret.table_ = json.tableIDs.map((id: string) => {
      return ret.cardsByID_[id];
    });
    ret.tributeRow_ = json.tributeRowIDs.map(
      (id: string) => ret.tributeCardsByID_[id],
    );
    ret.basicPile_ = json.basicPileIDs.map((id: string) => {
      return ret.cardsByID_[id];
    });
    ret.discard_ = json.discardIDs.map((id: string) => {
      return ret.cardsByID_[id];
    });
    ret.tributeDiscard_ = json.tributeDiscardIDs.map((id: string) => {
      return ret.tributeCardsByID_[id];
    });

    ret.players_ = json.players.map((player: any) => {
      return Player.fromJSON(player, ret.cardsByID_);
    });

    ret.age_ = json.age;
    ret.turn_ = json.turn;
    ret.phase_ = json.phase;

    ret.gameStartTimestamp_ = json.gameStartTimestamp;
    ret.gameEndTimestamp_ = json.gameEndTimestamp;
    ret.gameUpdateTimestamp_ =
      json.gameUpdateTimestamp_ ||
      json.gameEndTimestamp ||
      json.gameStartTimestamp;

    ret.bidsByPlayerID_ = json.bidsByPlayerID;
    ret.resolvesByPlayerID_ = json.resolvesByPlayerID || {};
    ret.rollSelectionsByPlayerID_ = json.rollSelectionsByPlayerID;
    ret.planningDataByPlayerID_ = json.planningDataByPlayerID;
    ret.resolutionDataByPlayerID_ = json.resolutionDataByPlayerID;
    ret.productionDataByPlayerID_ = json.productionDataByPlayerID || {};
    ret.rollsByPlayerID_ = json.rollsByPlayerID;
    ret.rerollRound_ = json.rerollRound;

    ret.events_ = json.events;
    ret.sequenceID_ = json.sequenceID;
    ret.fetchedSequenceID_ = json.sequenceID;

    ret.totalThinkingMillisByPlayerID_ = json.totalThinkingMillisByPlayerID;
    ret.phaseThinkingMillisByPlayerID_ = json.phaseThinkingMillisByPlayerID;
    ret.phaseStartTimestamp_ = json.phaseStartTimestamp;
    ret.phaseTimingData_ = json.phaseTimingData;

    ret.boardSize_ = json.boardSize;
    ret.tributeRowSize_ = json.tributeRowSize;
    ret.turnsPerAge_ = json.turnsPerAge;

    return ret;
  }

  render(viewer_id?: string) {
    let rollsByPlayerID: { [playerID: string]: number[] | null } = {};
    let bidsByPlayerID: { [playerID: string]: Rules.Bid } = {};
    if (viewer_id) {
      if (this.rollsByPlayerID_[viewer_id]) {
        rollsByPlayerID[viewer_id] = this.rollsByPlayerID_[viewer_id];
      }
      if (this.bidsByPlayerID_[viewer_id]) {
        bidsByPlayerID[viewer_id] = this.bidsByPlayerID_[viewer_id];
      }
    }

    const players = this.players_.map((player) => player.render());
    if (this.options_.hideFavor && !this.isFinished()) {
      players.forEach((player) => {
        player.counters.favor = 0;
      });
    }

    return {
      id: this.id_,
      sequenceID: this.sequenceID_,
      players,
      options: this.options_,

      readyByUserID: Object.fromEntries(
        this.players_.map((player) => {
          // TODO replace this with a helper isPlayerReady, use it in handleSelectionsIfNecessary
          const ready = match(this.phase_)
            .with(Phases.PLANNING, () => {
              return !!this.bidsByPlayerID_[player.getID()];
            })
            .with(Phases.RESOLUTION, () => {
              return !!this.resolvesByPlayerID_[player.getID()];
            })
            .with(Phases.WAR, () => {
              return !!this.rollSelectionsByPlayerID_[player.getID()];
            })
            .with(Phases.PRODUCTION, () => {
              return true;
            })
            .with(Phases.TRIBUTE, () => {
              return true;
            })
            .with(Phases.SETUP, () => {
              return true;
            })
            .exhaustive();
          return [player.getID(), ready];
        }),
      ),
      productionDataByPlayerID: this.productionDataByPlayerID_,
      planningDataByPlayerID: this.planningDataByPlayerID_,
      bidsByPlayerID,
      rollsByPlayerID,
      rerollRound: this.rerollRound_,
      tableIDs: this.table_.map((card) => card.id),
      tributeRowIDs: this.tributeRow_.map((card) => card.id),
      basicPileIDs: this.basicPile_.map((card) => card.id),

      age: this.age_,
      turn: this.turn_,
      phase: this.phase_,
      events: this.events_,
      cardsByID: this.cardsByID_,
      tributeCardsByID: this.tributeCardsByID_,
      gameEndTimestamp: this.gameEndTimestamp_,

      boardSize: this.boardSize_,
      tributeRowSize: this.tributeRowSize_,
      turnsPerAge: this.turnsPerAge_,

      gameStartTimestamp: this.gameStartTimestamp_,
      phaseStartTimestamp: this.phaseStartTimestamp_,
      totalThinkingMillisByPlayerID: this.totalThinkingMillisByPlayerID_,
      phaseThinkingMillisByPlayerID: this.phaseThinkingMillisByPlayerID_,
    };
  }

  debug_log(...content: any[]) {
    if (this.verbose_) {
      console.log(content);
    }
  }

  static inflatePlayer(
    player: RenderedPlayer,
    cardsByID: { [key: string]: Rules.CardWithID },
  ) {
    let to_card = (cardID: string): Rules.CardWithID => cardsByID[cardID];

    return {
      ...player,
      cards: player.cardIDs.map(to_card),
      selectedCard: player.selectedCardID
        ? to_card(player.selectedCardID)
        : null,
    };
  }

  static inflateGame(game: RenderedGame) {
    const to_card = (cardID: string): Rules.CardWithID =>
      game.cardsByID[cardID];
    const to_tribute_card = (cardID: string): Rules.TributeCardWithID =>
      game.tributeCardsByID[cardID];

    return {
      ...game,
      table: game.tableIDs.map(to_card),
      tributeRow: game.tributeRowIDs.map(to_tribute_card),
      basicPile: game.basicPileIDs.map(to_card),
      players: game.players.map((player) =>
        Game.inflatePlayer(player, game.cardsByID),
      ),
    };
  }
}

function migrateGameJSON(json: any): any {
  if (json.version === undefined) {
    json.version = 1;
    console.log('migrated from no version to version 1');
  }
  if (json.version === 1) {
    const cardByIDOriginal = json.cardsByID;
    json.tributeCardsByID = [];
    json.cardsByID = [];

    type OldCardByID = {
      id: string;
    } & (
      | {
          type: 'card';
          cardIndex: number;
        }
      | { type: 'tribute'; tributeIndex: number }
    );

    for (const card of Object.values<OldCardByID>(cardByIDOriginal)) {
      if (card.type === 'tribute') {
        json.tributeCardsByID.push([card.id, card.tributeIndex]);
      } else {
        json.cardsByID.push([card.id, card.cardIndex]);
      }
    }
    json.version = 2;
    console.log('migrated from version 1 to version 2');
  }

  if (json.version === 2) {
    type OldCardDef = {
      type: string;
      subType: CardType;
      affinity: string;
      baseEffect: string | undefined;
      turnEffect: string | undefined;
      gainSelfEffect: string | undefined;
      draftOtherEffect: string | undefined;
      gainOtherEffect: string | undefined;
      endOfGameEffect: string | undefined;
      additionalCosts: string | undefined;
    };
    // Migrate card defs
    // renamed fields type -> affinity, subType -> type
    // changed Resource, Conflict, Prayer to Derion, Tyros, Zeona
    json.cardDefinitions.forEach((cardDef: OldCardDef) => {
      if (cardDef.type === 'Resource') {
        cardDef.type = 'Derion';
      } else if (cardDef.type === 'Conflict') {
        cardDef.type = 'Tyros';
      } else if (cardDef.type === 'Prayer') {
        cardDef.type = 'Zeona';
      }
      cardDef.affinity = assertCardAffinity(cardDef.type);
      cardDef.type = assertCardType(cardDef.subType);

      cardDef.baseEffect = migrateCardChars2To3(cardDef.baseEffect);
      cardDef.turnEffect = migrateCardChars2To3(cardDef.turnEffect);
      cardDef.gainSelfEffect = migrateCardChars2To3(cardDef.gainSelfEffect);
      cardDef.draftOtherEffect = migrateCardChars2To3(cardDef.draftOtherEffect);
      cardDef.gainOtherEffect = migrateCardChars2To3(cardDef.gainOtherEffect);
      cardDef.endOfGameEffect = migrateCardChars2To3(cardDef.endOfGameEffect);
      cardDef.additionalCosts = migrateCardChars2To3(cardDef.additionalCosts);

      // verify card can be parsed
      getAllCardAbilities(cardDef as unknown as CardDef);
    });

    // migrate tribute defs
    json.tributeCardDefinitions.forEach((tributeDef: any) => {
      // fixup params, type -> affinity values
      if (tributeDef.params === 'Resource') {
        tributeDef.params = 'Derion';
      } else if (tributeDef.params === 'Conflict') {
        tributeDef.params = 'Tyros';
      } else if (tributeDef.params === 'Prayer') {
        tributeDef.params = 'Zeona';
      }
      // verify tribute can be parsed
      TributeLogic.getTributeLogic(tributeDef as unknown as TributeDef);
    });

    json.version = 3;
    console.log('migrated from version 2 to version 3');
  }

  return json;
}

type PlayerWarInfo = {
  playerID: string;
  totalBonus: number;
  rolls: number[];
  totalFinal: number;
  hasReroll: boolean;
};

type ClashResults = {
  cardID: string;
  rounds: ConflictRoundInfo[];
  winningPlayerID: string;
};
