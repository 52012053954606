/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/react';
import invariant from 'invariant';
import React from 'react';

import BaseCardView, { BaseCardBoxShadow } from './BaseCardView';
import TextWithSymbols, { PerAgePillOptions } from './TextWithSymbols';

import { css } from '@emotion/react';
import { TributeDef } from '@mythos/game/GameModel';
import { getTributeLogic, TributeLogic } from '@mythos/game/TributeLogic';
import nullthrows from 'nullthrows';
import Symbols from 'Symbols';
import TributeCardBG from '../assets/CardBackgroundEpic.jpg';
import FavorBannerBackground from '../assets/favor_banner.svg';
import TributeImageBaeren from '../assets/tribute_card/TributeArtBaeren.jpg';
import TributeImageDerion from '../assets/tribute_card/TributeArtDerionCropped.jpg';
import TributeImageTyros from '../assets/tribute_card/TributeArtTyrosCropped.jpg';
import TributeImageZeona from '../assets/tribute_card/TributeArtZeonaCropped.jpg';
import { EffectPill, SymbolWithNumber } from './CardHelpers';

type TributeCardEventHandler<TCard> = (
  card: TCard,
  event: React.MouseEvent,
) => void;

export type TributeCardRenderer<TCard extends TributeDef> = (
  props: Props<TCard>,
) => React.ReactElement;

type Props<TCard extends TributeDef = TributeDef> = {
  card: TCard;

  onCardEnter?: TributeCardEventHandler<TCard>;
  onCardLeave?: TributeCardEventHandler<TCard>;
  onClick?: TributeCardEventHandler<TCard>;

  overlayStyle?: React.CSSProperties;
  overlayCSS?: SerializedStyles;
  overlayChildren?: React.ReactNode;
} & (
  | {
      mini: true;
      currentAge: number;
    }
  | {
      mini?: false;
      currentAge?: number;
    }
);

const TributeCardView = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    let { card, currentAge, mini } = props;
    const handleMouseEnter = (e: React.MouseEvent) => {
      props.onCardEnter && props.onCardEnter(card, e);
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
      props.onCardLeave && props.onCardLeave(card, e);
    };

    const handleClick = (e: React.MouseEvent) => {
      props.onClick && props.onClick(card, e);
    };

    const tributeLogic = getTributeLogic(card);

    let card_style: React.CSSProperties = {
      backgroundImage: `url(${TributeCardBG})`,
      ...box_shadow_style(card.image),
      transform: 'rotate(90)',
    };

    let body_style = {
      backgroundColor: 'rgba(0, 0, 0)',
    };

    let title_view = <div css={TributeStyle.name}>{card.name}</div>;

    let favor_display = (
      <div css={TributeStyle.favorContainer}>
        {card.favor.map((favor, i) => {
          const age = i + 1;
          const isHighlighted = age === currentAge;
          const isDimmed = currentAge !== undefined && !isHighlighted;
          let additionalCss: SerializedStyles | null = null;
          if (age === 1) {
            additionalCss = TributeStyle.favorCellLeft;
          } else if (age === 3) {
            additionalCss = TributeStyle.favorCellRight;
          }
          return (
            <div key={i} css={[TributeStyle.favorCell, additionalCss]}>
              <EffectPill
                output={{
                  outputResource: 'favor',
                  outputRatio: favor,
                }}
                hideChrome
                css={TributeStyle.favorPill}
                style={{
                  fontWeight: 'bold',
                  opacity: isDimmed ? 0.5 : 1,
                }}
              />
            </div>
          );
        })}
      </div>
    );

    const thresholds = tributeLogic?.thresholds;
    const description = tributeLogic?.description();

    return (
      <BaseCardView
        className={'tribute'}
        ref={ref}
        bodyStyle={body_style}
        cardStyle={card_style}
        titleView={title_view}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onLeftClick={handleClick}
        overlayChildren={props.overlayChildren}
        overlayCSS={props.overlayCSS}
        overlayStyle={props.overlayStyle}
        landscape={true}
        mini={mini}
      >
        {mini ? (
          <MiniTributeContent
            tributeLogic={nullthrows(tributeLogic)}
            card={card}
            currentAge={nullthrows(currentAge)}
          />
        ) : (
          <div css={TributeStyle.body}>
            <div css={TributeStyle.imageContainer}>
              <div css={TributeStyle.imageFade} />
              <img
                css={TributeStyle.image}
                src={card.image && TRIBUTE_TYPE_TO_IMAGE[card.image]}
              />
            </div>
            <div css={TributeStyle.effect_container}>
              {description && (
                <TextWithSymbols
                  css={TributeStyle.effect}
                  content={description}
                  perAgePillOptions={
                    thresholds
                      ? {
                          display: 'all-values',
                          values: thresholds,
                          currentAge,
                        }
                      : undefined
                  }
                />
              )}
            </div>
            {favor_display}
          </div>
        )}
      </BaseCardView>
    );
  },
);

const MiniTributeContent = (props: {
  card: TributeDef;
  tributeLogic: TributeLogic;
  currentAge: number;
}) => {
  const { tributeLogic, card, currentAge } = props;
  const thresholds = tributeLogic?.thresholds;
  const perAgePillOptions: PerAgePillOptions | undefined = thresholds
    ? currentAge
      ? {
          display: 'single-value',
          values: thresholds,
          currentAge,
        }
      : {
          display: 'all-values',
          values: thresholds,
          currentAge,
        }
    : undefined;

  let favorDisplay = (
    <div css={TributeStyle.mini_favor_container}>
      <SymbolWithNumber
        symbol={Symbols.FAVOR}
        number={card.favor[currentAge - 1]}
        height={16}
      />
    </div>
  );

  return (
    <div css={TributeStyle.body}>
      <div css={TributeStyle.mini_effect_container}>
        <TextWithSymbols
          css={TributeStyle.mini_effect}
          content={tributeLogic.description()}
          perAgePillOptions={perAgePillOptions}
        />
        {favorDisplay}
      </div>
    </div>
  );
};

export default TributeCardView;

export const BasicTributeRenderer = (props: Props) => {
  return <TributeCardView {...props} />;
};
export const MakeHighlightedAgeTributeRenderer = (age: number) => {
  return (props: Props) => {
    return BasicTributeRenderer({ ...props, currentAge: age });
  };
};

export const TRIBUTE_TYPE_TO_IMAGE: Record<string, string> = {
  Baeren: TributeImageBaeren,
  Derion: TributeImageDerion,
  Zeona: TributeImageZeona,
  Tyros: TributeImageTyros,
};
const TributeStyle = {
  name: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    backgroundColor: 'rgba(218, 218, 218, 0.85)',
    fontSize: 10,
    '.mini &': {
      fontSize: 9,
    },
    height: 11,
    margin: 3,
    marginBottom: 2,

    fontFamily: 'Mythos-CardTitle',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    position: 'relative',
    flexGrow: 1,
  }),
  imageContainer: css({
    width: '40%',
  }),
  image: css({
    width: '100%',
    objectFit: 'cover',
  }),
  imageFade: css({
    height: '100%',
    width: '100%',
    position: 'absolute',
    background:
      '-webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 30%, rgba(29,41,74,1) 36%, rgba(14,23,46,1) 100%);',
  }),
  effect_container: css({
    width: '67%',
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    position: 'relative',
  }),
  effect: css({
    fontSize: 9.5,
    color: 'rgb(211, 211, 211)',
    textAlign: 'center',
    padding: 2,
    marginLeft: -8,
    paddingBottom: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
    fontWeight: 400,
    lineHeight: 1.3,
  }),
  mini_effect_container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    position: 'relative',
  }),
  mini_effect: css({
    fontSize: 9.5,
    color: 'rgb(211, 211, 211)',
    textAlign: 'center',
    padding: 2,
    marginTop: 'auto',
    marginBottom: 'auto',
    fontWeight: 400,
    lineHeight: 1.3,
  }),
  mini_favor_container: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 5,
  }),
  favorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '70',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 11,

    backgroundImage: `url(${FavorBannerBackground})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
  }),
  favorCell: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
    width: '26%',
    bottom: 3,
  }),
  favorCellLeft: css({
    width: '37%',
    bottom: 2,
  }),
  favorCellRight: css({
    width: '37%',
    bottom: 2,
  }),
  favorPill: css({
    margin: 1,
  }),
  bannerAge: css({
    textAlign: 'center',
    alignSelf: 'center',
    minWidth: 6,
    marginLeft: 1,

    color: 'rgb(221, 221, 221)',
    fontSize: 6,
  }),
  highlightedAge: css({
    color: 'rgb(227, 187, 53)',
    fontSize: 8,
  }),
} as const;

const GodColors = {
  Akroa: 'rgba(126, 106, 6, 0.57)',
  Baeren: 'rgba(25, 25, 45, 0.57)',
  Derion: 'rgba(0, 87, 30, 0.57)',
  Tyros: 'rgba(145, 30, 0, 0.57)',
  Zeona: 'rgba(0, 36, 145, 0.57)',
} as const;

function box_shadow_style(god: string | undefined) {
  if (!god) {
    return {};
  }
  invariant(god in GodColors, 'missing color for god "%s"', god);
  let color = GodColors[god as keyof typeof GodColors];

  return {
    boxShadow: `inset 0px 0px 20px 0px ${color}, ${BaseCardBoxShadow}`,
  };
}
