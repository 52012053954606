/** @jsx jsx */
/** @jsxFrag */
import { css, jsx, SerializedStyles } from '@emotion/react';
import React, { ReactElement } from 'react';
import { times } from 'underscore';

import { CardAffinity, CardType } from '@mythos/game/CardTypes';
import { CardDef } from '@mythos/game/GameModel';
import { ageString } from '@mythos/game/Utility';
import BaseCardView from './BaseCardView';
import {
  BaseResourceToSymbol,
  CardAffinityToSymbol,
  CardTypeToSymbol,
  ResourceToSymbol,
} from './Symbols';

import CardBGBasic from '../assets/CardBackgroundBasicResource.jpg';
import CardBGConflict from '../assets/CardBackgroundConflict.jpg';
import CardBGPrayer from '../assets/CardBackgroundPrayer.jpg';
import CardBGResource from '../assets/CardBackgroundResource.jpg';
import CardBGTribute from '../assets/CardBackgroundTribute.jpg';

import UpgradeBorderBasic from '../assets/generated/UpgradeBorderBasic.svg';
import UpgradeBorderConflict from '../assets/generated/UpgradeBorderConflict.svg';
import UpgradeBorderLeader from '../assets/generated/UpgradeBorderLeader.svg';
import UpgradeBorderPrayer from '../assets/generated/UpgradeBorderPrayer.svg';
import UpgradeBorderResource from '../assets/generated/UpgradeBorderResource.svg';
const CardTypeToUpgradeBorder: Record<CardAffinity, string> = {
  [CardAffinity.Derion]: UpgradeBorderResource,
  [CardAffinity.Tyros]: UpgradeBorderConflict,
  [CardAffinity.Zeona]: UpgradeBorderPrayer,
  [CardAffinity.Leader]: UpgradeBorderLeader,
  [CardAffinity.Basic]: UpgradeBorderBasic,
  [CardAffinity.None]: UpgradeBorderLeader,
};

import {
  AbilityOutput,
  AbilityUpgradeCondition,
  abilityUpgradeConditionToString,
  areAbilityUpgradeConditionsEqual,
  BaseCardAbility,
  BaseCountersAbility,
  CardAdditionalCosts,
  DraftCardAbility,
  EachTurnAbility,
  EndOfGameAbility,
  GainOtherCardAbility,
  GainSelfAbility,
  getAdditionalCosts,
  getAllCardAbilities,
  getGainSelfCardAbility,
} from '@mythos/game/CardAbilities';
import { Token } from '@mythos/game/Resources';
import { CardWithID } from '@mythos/game/Rules';
import { HoverCardRenderer } from 'HoverCardStore';
import invariant from 'invariant';
import nullthrows from 'nullthrows';
import { match, P } from 'ts-pattern';
import { CardArt } from '../generated/CardArt';
import CardBanner from './CardBanner';
import {
  AllResourceEffectPill,
  EffectPill,
  EffectTriggerPrefix,
  GainOtherTriggerPrefix,
} from './CardHelpers';
import {
  CardTypeToEffectBGStyle,
  CardTypeToFrameColor,
} from './CardTypeToFrameColor';
import Symbols, { SymbolView } from './Symbols';

export type CardEventFunc<TCard> = (
  card: TCard,
  event: React.MouseEvent,
) => void;
export type CardRenderer = (props: Props) => ReactElement;

interface Props<TCard extends CardDef = CardDef> {
  onCardEnter?: CardEventFunc<TCard>;
  onCardLeave?: CardEventFunc<TCard>;
  onLeftClick?: CardEventFunc<TCard>;
  onRightClick?: CardEventFunc<TCard>;

  card: TCard;

  overlayStyle?: React.CSSProperties;
  overlayChildren?: React.ReactNode;
  overlayCSS?: SerializedStyles;

  mini?: boolean;
}

const CardView = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { card, overlayStyle, overlayCSS, overlayChildren, mini } = props;

  const handleMouseEnter = (e: React.MouseEvent) => {
    props.onCardEnter && props.onCardEnter(card, e);
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    props.onCardLeave && props.onCardLeave(card, e);
  };

  const handleLeftClick = (e: React.MouseEvent) => {
    props.onLeftClick && props.onLeftClick(card, e);
  };

  const handleRightClick = (e: React.MouseEvent) => {
    if (props.onRightClick) {
      e.preventDefault();
      props.onRightClick(card, e);
    }
  };

  let card_style: React.CSSProperties = {
    backgroundImage: `url(${CardTypeToBackgroundImage[card.affinity]})`,
  };

  const art = card.imageName && CardArt[card.imageName as keyof typeof CardArt];
  let body_style: React.CSSProperties = {
    backgroundImage: (art && `url(${art})`) || undefined,
    backgroundSize: '100%',
    position: 'relative',
    border: `1px solid ${CardTypeToFrameColor[card.affinity]}`,
  };

  let title_view = (
    <div css={CardStyle.name}>
      <div
        css={CardStyle.innerBorder}
        style={{
          border: `1px solid ${CardTypeToFrameColor[card.affinity]}`,
        }}
      />

      <CardBanner
        css={CardStyle.goldBanner}
        borderColor={CardTypeToFrameColor[card.affinity]}
        // backgroundColor="rgba(0, 0, 0, 0.5)"
        // backgroundColor={CardTypeToFrameColor[card.type]}
        // backgroundColor="rgba(255, 255, 255, 0.25)"
        // backgroundColor="rgba(0, 0, 0, 0.25)"
        bannerType="left-top-shoulder"
      >
        <EffectPill
          css={CardStyle.titleGoldPill}
          output={{ outputResource: 'gold', outputRatio: card.cost }}
          hideChrome
        />
      </CardBanner>

      {card.name}

      <CardBanner
        css={CardStyle.favorBanner}
        borderColor={CardTypeToFrameColor[card.affinity]}
        bannerType="right-top-shoulder"
        // backgroundColor={CardTypeToFrameColor[card.type]}
        // backgroundColor="rgba(0, 0, 0, 0.25)"
      >
        <EffectPill
          css={CardStyle.titleFavorPill}
          output={{ outputResource: 'favor', outputRatio: card.favor }}
          hideChrome
        />
      </CardBanner>
    </div>
  );

  return (
    <BaseCardView
      ref={ref}
      mini={mini}
      bodyStyle={body_style}
      cardStyle={card_style}
      overlayStyle={overlayStyle}
      overlayCSS={overlayCSS}
      overlayChildren={overlayChildren}
      titleView={title_view}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onLeftClick={handleLeftClick}
      onRightClick={handleRightClick}
    >
      <ExtraCostsView
        borderColor={CardTypeToFrameColor[card.affinity]}
        additionalCosts={getAdditionalCosts(card)}
      />
      <GainSelfEffect
        ability={getGainSelfCardAbility(card)}
        borderColor={CardTypeToFrameColor[card.affinity]}
      />
      <div style={{ flexGrow: 1 }}></div>

      <CardFooter card={card} />
      {(card.age && (
        <CardBanner
          css={CardStyle.age}
          borderColor={CardTypeToFrameColor[card.affinity]}
          bannerType="right-bottom-shoulder"
        >
          <div css={CardStyle.ageText}>{ageString(card.age)}</div>
        </CardBanner>
      )) ||
        null}
    </BaseCardView>
  );
});
export default CardView;

export function BasicCardRenderer(props: Props) {
  return <CardView {...props} />;
}
export function makeMultiCardRenderer(cardsByID: {
  [key: string]: CardWithID;
}): HoverCardRenderer {
  return (props: { card: string[] }) => {
    const cardIDs = props.card;
    return <MultiCardDefRenderer card={cardIDs.map((id) => cardsByID[id])} />;
  };
}
export const MultiCardDefRenderer = (props: { card: CardWithID[] }) => {
  return (
    <div css={MultiCardContainerStyle}>
      {props.card.map((card) => {
        return (
          <div css={MultiCardCardContainerStyle}>
            <CardView card={card} />
          </div>
        );
      })}
    </div>
  );
};
const MultiCardContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  transform: 'scale(0.5)',
  transformOrigin: 'top right',
});
const MultiCardCardContainerStyle = css({});

const CardTypeToBackgroundImage = {
  [CardAffinity.Basic]: CardBGBasic,
  [CardAffinity.Derion]: CardBGResource,
  [CardAffinity.Tyros]: CardBGConflict,
  [CardAffinity.Zeona]: CardBGPrayer,
  [CardAffinity.Leader]: CardBGTribute,
  [CardAffinity.None]: CardBGTribute,
} as const;

function CardFooterTypeView(props: { card: CardDef }) {
  const symbol = CardAffinityToSymbol[props.card.affinity];
  return (
    <CardBanner
      css={CardStyle.footerCardTypeBanner}
      bannerType="right-bottom-shoulder"
      borderColor={CardTypeToFrameColor[props.card.affinity]}
    >
      <SymbolView symbol={symbol} css={CardStyle.footerCardType} />
    </CardBanner>
  );
}
function CardFooterSubTypeView(props: { card: CardDef }) {
  const symbol = CardTypeToSymbol[props.card.type];
  return (
    <CardBanner
      css={CardStyle.footerCardSubTypeBanner}
      bannerType="left-bottom-shoulder"
      borderColor={CardTypeToFrameColor[props.card.affinity]}
      backgroundColor="rgb(160, 160, 160)"
    >
      <SymbolView symbol={symbol} css={CardStyle.footerCardSubType} />
    </CardBanner>
  );
}

function CardFooter(props: { card: CardDef }) {
  const frameColor = CardTypeToFrameColor[props.card.affinity];
  return (
    <div
      css={CardStyle.footer}
      style={{
        borderTop: `1px solid ${frameColor}`,
      }}
    >
      <CardEffects card={props.card} />
      <CardFooterTypeView card={props.card} />
      <CardFooterSubTypeView card={props.card} />
    </div>
  );
}

const CardStyle = {
  name: css({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'left',
    // borderRadius: 3,
    backgroundColor: 'rgba(218, 218, 218, 0.85)',

    margin: 3,
    marginTop: 2,
    marginBottom: 0,
    paddingLeft: 16,
    paddingRight: 16,

    height: 14,
    // width: 84,

    flex: '0 0 auto',
    position: 'relative',

    fontFamily: 'Mythos-CardTitle',
    fontSize: 6,
  }),
  innerBorder: css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '1px solid rgba(0, 0, 0, 0.5)',
    // zIndex: -1,
  }),
  favorBanner: css({
    position: 'absolute',
    right: -3,
    top: -1,
  }),
  goldBanner: css({
    // display: 'none',
    position: 'absolute',
    top: -1,
    left: -3,
  }),
  titleFavorPill: css({
    margin: 1,
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 2,
  }),
  titleGoldPill: css({
    margin: 1,
    marginTop: 2,
    marginBottom: 2,
    marginRight: 2,
  }),
  age: css({
    position: 'absolute',
    bottom: 7,
    right: -4,
    paddingRight: 2,
    paddingLeft: 3,
    paddingTop: 1.5,
    paddingBottom: 2.5,
  }),
  ageText: css({
    textAlign: 'center',
    width: 5,
    height: 4,
    fontSize: 5,
    fontWeight: 'bold',
    color: 'black',
    // textShadow: '0px 0px 2px black',
  }),
  footer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'rgba(219, 219, 219, 0.8)',

    // ...OverlayCardBGStyle,
  }),
  footerCardTypeBanner: css({
    position: 'absolute',
    right: -4,
    bottom: -4.5,
  }),
  footerCardSubTypeBanner: css({
    position: 'absolute',
    left: -4,
    bottom: -4.5,
  }),
  footerCardType: css({
    fontSize: 8,
    height: 8,
    width: 9,
    padding: 1,
    paddingLeft: 1.5,
    paddingRight: 0.5,
  }),
  footerCardSubType: css({
    fontSize: 8,
    height: 8,
    width: 9,
    padding: 1,
    paddingLeft: 0.5,
    paddingRight: 1.5,
  }),
  footerAbilities: css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }),
} as const;

export function ExtraCostsView(props: {
  borderColor: string;
  additionalCosts?: CardAdditionalCosts | null;
}) {
  const { borderColor, additionalCosts } = props;
  if (!additionalCosts) {
    return null;
  }

  return (
    <div css={ExtraCostsStyles.container}>
      {additionalCosts.warTokens > 0 && (
        <CardBanner
          css={ExtraCostsStyles.banner}
          borderColor={borderColor}
          backgroundColor="rgba(210, 210, 210, 0.25)"
          bannerType="left"
        >
          <EffectPill
            hideChrome
            output={{
              outputResource: 'warTokens',
              outputRatio: additionalCosts.warTokens,
            }}
            css={ExtraCostsStyles.pill}
          />
        </CardBanner>
      )}
    </div>
  );
}
const ExtraCostsStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',

    position: 'absolute',
    top: 0,
    left: -4,
  }),
  banner: css({}),
  pill: css({
    margin: 1,
  }),
};

export function GainSelfEffect(props: {
  className?: string;
  style?: React.CSSProperties;
  ability?: GainSelfAbility | null;
  borderColor: string;
}) {
  const { className, style, ability, borderColor } = props;
  if (!ability) {
    return null;
  }
  return (
    <div
      css={GainSelfEffectStyles.container}
      className={className}
      style={style}
    >
      {ability.outputs.map((output, i) => (
        <CardBanner
          key={i}
          css={GainSelfEffectStyles.banner}
          bannerType="right"
          borderColor={borderColor}
          backgroundColor="rgba(210, 210, 210, 0.25)"
          // backgroundColor="rgba(0, 0, 0, 0.5)"
        >
          <EffectPill
            output={output}
            hideChrome
            css={GainSelfEffectStyles.pill}
          />
        </CardBanner>
      ))}
    </div>
  );
}
const GainSelfEffectStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  }),
  banner: css({
    // marginRight: -0.5,
    marginRight: -4,
  }),
  pill: css({
    margin: 1,
  }),
};

function CardEffects(props: { card: CardDef }) {
  let effects: React.ReactNode[] = [];

  const { nonupgradedEffectRows, effectRowsByUpgrade, hasEmptyRow } =
    computeCardEffectRows(props.card);

  // NOTE: this will render upgrades in the order written in the card def
  // Each ability will be rendered in its own CardEffect once per upgrade condition
  // XXX: if multiple abilities have the same upgrade condition, the upgrade condition will be rendered multiple times
  for (const upgradeCondition of effectRowsByUpgrade.keys()) {
    for (const ability of effectRowsByUpgrade.get(upgradeCondition) ?? []) {
      effects.push(
        <CardEffect
          key={effects.length}
          cardType={props.card.affinity}
          ability={ability}
          upgradeCondition={upgradeCondition}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          }}
          topBorderChildren={
            <UpgradeConditionBorder
              cardType={props.card.affinity}
              upgradeCondition={upgradeCondition}
            />
          }
        />,
      );
    }
  }

  for (const ability of nonupgradedEffectRows) {
    const effect = (
      <CardEffect
        key={`base${effects.length}`}
        cardType={props.card.affinity}
        ability={ability}
        upgradeCondition={null}
      />
    );
    effects.push(effect);
  }
  if (hasEmptyRow) {
    effects.push(
      <EmptyCardEffect key="empty" cardType={props.card.affinity} />,
    );
  }
  return (
    <div
      css={CardStyle.footerAbilities}
      style={CardTypeToEffectBGStyle[props.card.affinity]}
    >
      {effects}
    </div>
  );
}

function UpgradeConditionBorder(props: {
  cardType: CardAffinity;
  upgradeCondition: AbilityUpgradeCondition;
}) {
  const { cardType, upgradeCondition } = props;

  const borderImage = CardTypeToUpgradeBorder[cardType];

  return (
    <>
      <img src={borderImage} css={CardEffectStyles.upgradeBorder} />
      <UpgradeCondition
        css={CardEffectStyles.upgradeConditionInBorder}
        upgradeCondition={upgradeCondition}
      />
    </>
  );
}
export function UpgradeCondition(props: {
  className?: string;
  upgradeCondition: AbilityUpgradeCondition;
}) {
  const { className, upgradeCondition } = props;

  const upgradeAmount = upgradeCondition.upgradeAmount;
  const upgradeType = upgradeCondition.upgradeType;
  const upgradeSymbol = match(upgradeType)
    .with(CardAffinity.Tyros, () => Symbols.CARD_TYPE_CONFLICT)
    .with(CardAffinity.Derion, () => Symbols.CARD_TYPE_RESOURCE)
    .with(CardAffinity.Zeona, () => Symbols.CARD_TYPE_PRAYER)
    .with(CardType.Unit, () => Symbols.CARD_SUBTYPE_UNIT)
    .with(CardType.Building, () => Symbols.CARD_SUBTYPE_BUILDING)
    .with(CardType.Event, () => Symbols.CARD_SUBTYPE_EVENT)
    .with(Token.War, () => Symbols.TOKEN_WAR)
    .with('card_set', () => Symbols.CARD_SET)
    .otherwise(() => {
      invariant(false, 'unhandled upgrade type');
    });
  return (
    <div className={className} css={CardEffectStyles.upgradeCondition}>
      <SymbolView
        symbol={Symbols.UPGRADE}
        css={CardEffectStyles.upgradeSymbol}
      />
      {upgradeAmount}
      <SymbolView symbol={upgradeSymbol} css={CardEffectStyles.upgradeSymbol} />
    </div>
  );
}

export function CardEffect(props: {
  ability: BaseCardAbility;
  cardType: CardAffinity;
  upgradeCondition: AbilityUpgradeCondition | null;
  style?: React.CSSProperties;
  topBorderChildren?: React.ReactNode;
}): React.ReactNode | null {
  const { ability, upgradeCondition, style, topBorderChildren } = props;

  let multiresourcePill: React.ReactNode | null = null;
  let containerCSS: SerializedStyles | undefined =
    CardEffectStyles.clippedContainer;
  let containerStyle: React.CSSProperties | undefined = {
    backgroundColor: CardTypeToFrameColor[props.cardType],
  };
  const abilityOutputs = ability.outputs.filter((o) =>
    areAbilityUpgradeConditionsEqual(
      o.upgradeCondition || null,
      upgradeCondition,
    ),
  );
  if (abilityOutputs.length === 0) {
    return null;
  }

  const prefix = match(ability)
    .with(P.instanceOf(GainSelfAbility), () => {
      invariant(false, 'unhandled GainSelfAbility');
    })
    .with(P.instanceOf(GainOtherCardAbility), (a) => {
      containerCSS = css([
        containerCSS,
        CardEffectStyles.clippedContainerOtherAbilities,
      ]);

      const targetType = a.getTargetType();
      if (targetType.target_type === 'tribute') {
        if (
          abilityOutputs.length === 3 &&
          abilityOutputs.every(
            (o) =>
              o.outputRatio === abilityOutputs[0].outputRatio && !o.multiplier,
          )
        ) {
          multiresourcePill = (
            <AllResourceEffectPill
              amount={`${abilityOutputs[0].outputRatio}`}
            />
          );
        }
        return <GainOtherTriggerPrefix targetType={targetType} />;
      } else if (targetType.target_type === 'token') {
        multiresourcePill = (
          <>
            {abilityOutputs.map((output, i) => {
              return <EffectPillOrSymbols key={i} output={output} />;
            })}
          </>
        );
        return <GainOtherTriggerPrefix targetType={targetType} />;
      } else if (targetType.target_type === 'card_type') {
        return <GainOtherTriggerPrefix targetType={targetType} />;
      } else if (targetType.target_type === 'card_affinity') {
        return <GainOtherTriggerPrefix targetType={targetType} />;
      }
    })
    .with(P.instanceOf(EachTurnAbility), (a) => {
      multiresourcePill = (
        <>
          {abilityOutputs.map((output, i) => {
            return <EffectPillOrSymbols key={i} output={output} />;
          })}
        </>
      );
      return <EffectTriggerPrefix symbol={Symbols.EFFECT_PRODUCTION} />;
    })
    .with(P.instanceOf(DraftCardAbility), (a) => {
      const symbol = match(a.getDraftTarget)
        .with(CardAffinity.Tyros, () => Symbols.M3_DRAFT_CONFLICT)
        .with(CardAffinity.Derion, () => Symbols.M3_DRAFT_RESOURCE)
        .with(CardAffinity.Zeona, () => Symbols.M3_DRAFT_PRAYER)
        .otherwise(() => {
          console.warn('no symbol for', a.getDraftTarget);
          return null;
        });
      return symbol && <EffectTriggerPrefix symbol={symbol} />;
    })
    .with(P.instanceOf(BaseCountersAbility), () => {
      containerCSS = undefined;
      containerStyle = undefined;
      multiresourcePill = (
        <>
          {abilityOutputs.map((output, i) => {
            invariant(!output.multiplier, 'unexpected base multiplier');

            return times(output.outputRatio, (j) => (
              <SymbolView
                key={j}
                symbol={nullthrows(BaseResourceToSymbol[output.outputResource])}
                css={CardEffectStyles.baseSymbol}
              />
            ));
          })}
        </>
      );
      return null;
    })
    .with(P.instanceOf(EndOfGameAbility), () => {
      return <EffectTriggerPrefix symbol={Symbols.EOG} />;
    })
    .otherwise(() => {
      console.warn('no prefix for', ability);
      return null;
    });

  const outputs = abilityOutputs;
  const pills =
    multiresourcePill ??
    outputs.map((output, i) => <EffectPill key={i} output={output} />);
  let cardEffect = (
    <div
      css={[CardEffectStyles.cardEffect, containerCSS]}
      style={containerStyle}
    >
      {prefix}
      {pills}
    </div>
  );
  return (
    <div css={CardEffectStyles.cardEffectArea} style={style}>
      <div css={CardEffectStyles.cardEffectBar}>
        {topBorderChildren}
        {cardEffect}
      </div>
    </div>
  );
}
function EmptyCardEffect(props: { cardType: CardAffinity }) {
  return (
    <div css={CardEffectStyles.cardEffectArea}>
      <div css={CardEffectStyles.cardEffectBar} />
    </div>
  );
}

export type CardEffectRowsViewModel = {
  nonupgradedEffectRows: Set<BaseCardAbility>;
  effectRowsByUpgrade: Map<AbilityUpgradeCondition, Set<BaseCardAbility>>;
  hasEmptyRow: boolean;
};
export function computeCardEffectRows(card: CardDef): CardEffectRowsViewModel {
  let effectRowsByUpgrade = new Map<
    AbilityUpgradeCondition,
    Set<BaseCardAbility>
  >();

  const nonupgradedEffectRows = new Set<BaseCardAbility>();
  for (const ability of getAllCardAbilities(card)) {
    const usedUpgradeConditionStrings = new Set<string>();

    for (const output of ability.outputs) {
      const upgradeCondition = output.upgradeCondition;
      if (!upgradeCondition) {
        nonupgradedEffectRows.add(ability);
        continue;
      }

      const upgradeConditionString =
        abilityUpgradeConditionToString(upgradeCondition);
      if (usedUpgradeConditionStrings.has(upgradeConditionString)) {
        continue;
      }
      usedUpgradeConditionStrings.add(upgradeConditionString);

      if (!effectRowsByUpgrade.has(upgradeCondition)) {
        effectRowsByUpgrade.set(upgradeCondition, new Set([ability]));
      }
      effectRowsByUpgrade.get(upgradeCondition)!.add(ability);
    }
  }

  return {
    nonupgradedEffectRows,
    effectRowsByUpgrade,
    hasEmptyRow: nonupgradedEffectRows.size === 0,
  };
}

const CardEffectClipPath = `polygon(
    0% 50%,
    var(--notchSize) 0%,
    calc(100% - var(--notchSize)) 0%,
    100% 50%,
    calc(100% - var(--notchSize)) 100%,
    var(--notchSize) 100%
  )`;

const CardEffectStyles = {
  upgradeSymbol: css({
    height: 5,
    width: 'auto',
    paddingTop: 1,
    paddingLeft: 1,
    paddingRight: 1,
    paddingBottom: 0.5,
  }),
  upgradeBorder: css({
    height: 'auto',
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
  }),
  upgradeCondition: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 6,
    width: 'fit-content',
    alignItems: 'flex-end',
  }),
  upgradeConditionInBorder: css({
    position: 'absolute',
    top: '100%',
    left: 0,
    paddingLeft: 0,
    paddingRight: 1,
    paddingTop: 1.2,
  }),
  cardEffectArea: css({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
  }),
  cardEffectBar: css({
    height: '100%',
    position: 'relative',
    padding: 1,
    minHeight: 16,
  }),
  cardEffect: css({
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    height: '100%',
    width: 'fit-content',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  }),
  clippedContainer: css({
    '--notchSize': '3px',
    clipPath: CardEffectClipPath,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'relative',

    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 2,
    paddingBottom: 2,

    '&::before': {
      '--notchSize': '2.85px',
      clipPath: CardEffectClipPath,
      background:
        'linear-gradient(180deg, rgba(228,228,228,1) 0%, rgba(220,220,220,1) 74%, rgba(176,176,176,1) 100%);',
      content: '""',

      position: 'absolute',
      left: 1,
      top: 1,
      right: 1,
      bottom: 1,

      zIndex: -1,
    },
  }),
  clippedContainerOtherAbilities: css({
    '&::before': {
      background:
        'linear-gradient(180deg, rgba(255,225,164,1) 0%, rgba(182,139,74,1) 100%);',
    },
  }),
  resourceContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  baseSymbol: css({
    height: 12,
    width: 'auto',
  }),
  resourceSymbol: css({
    height: 12,
    width: 'auto',
    // marginLeft: -4,
    // '&:first-of-type': {
    //   marginLeft: 0,
    // },
  }),
};

function EffectPillOrSymbols(props: { output: AbilityOutput }) {
  const output = props.output;
  if (output.multiplier) {
    return <EffectPill output={output} />;
  }
  return (
    <div css={CardEffectStyles.resourceContainer}>
      {times(output.outputRatio, (j) => {
        return (
          <SymbolView
            key={j}
            symbol={ResourceToSymbol[output.outputResource]}
            css={CardEffectStyles.resourceSymbol}
          />
        );
      })}
    </div>
  );
}
