import { countBy } from 'underscore';

import { findReverse, randomFloatRange } from '@mythos/utils/utils';
import invariant from 'invariant';
import nullthrows from 'nullthrows';
import { match, P } from 'ts-pattern';
import _ from 'underscore';
import * as CardAbilities from '../game/CardAbilities';
import {
  AbilityUpgradeCondition,
  BaseCardAbility,
  computeOutputMultiplierForPlayer,
  getAllCardAbilities,
  upgradeConditionMet,
} from './CardAbilities';
import { CardAffinity } from './CardTypes';
import { InflatedGame, InflatedPlayer } from './Game';
import { EventTypes, GameEventOfType } from './GameEvents';
import Phases from './Phases';
import * as Rules from './Rules';
import { makeContextFromGame } from './Rules';
import {
  getTributeLogic,
  TributeLogicPlanningData,
  TributeLogicProductionData,
  TributeLogicResolutionData,
} from './TributeLogic';
import {
  addCounters,
  clamp,
  CounterDelta,
  debug_log,
  makeCounterDelta,
  subtractCounterDeltas,
} from './Utility';

const VERBOSE = false;

type PerAgeValue = [number, number, number];
// All preferences are a real number between 0 and 1, and positively correlated
export interface CardValuePreferences {
  tribute_value: number;
  card_output_values_by_age: Record<'gold' | 'military', PerAgeValue>;
  random_value_range?: [number, number];
  target_gold_output_by_age: PerAgeValue; // production + base
  military_supremacy_target: number; // how much more military output to have over opponents
  military_supremacy_preference: number; // how much to weigh that target
  card_flat_favor_preference_by_age: PerAgeValue;
  target_card_type_by_age: Record<CardAffinity, PerAgeValue>; // how many of each card type to have within age
  unaffordable_value_penalty: number; // how much to reduce value of unaffordable cards

  // Basic Bot 2 preferences
  card_cost_value_reduction_by_age: number[]; // how much to reduce value by cost of card
}

export interface BidPreferences {
  low_value_threshold_by_age: PerAgeValue; // bot will bid minimally for cards under this threshold
  high_value_threshold_by_age: PerAgeValue; // bot will bid aggressively for cards over this threshold
  conserve_military_factor_by_age: PerAgeValue; // how much to weigh conserving military (should be 0-1.0)
  bid_nothing_threshold_by_age: PerAgeValue; // value threshold
  bid_nothing_probability_by_age: PerAgeValue;
  random_value_range?: [number, number];
}

export function cardCompositionCounts(player: InflatedPlayer) {
  let board = player.cards;
  let counts = countBy(board, (card) => card.affinity);
  board.forEach((card) => {
    counts[card.type] = (counts[card.type] || 0) + 1;
  });
  return counts;
}

export function totalProductionCounters(context: Rules.Context): CounterDelta {
  const initial_production_delta = { ...context.production_delta };
  const production_context = Rules.handleProduction(context);
  return subtractCounterDeltas(
    production_context.production_delta,
    initial_production_delta,
  );
}

export type CardCostTuple = [Rules.CardWithID, number];
export function affordableCards(
  context: Rules.Context,
  cards: Rules.CardWithID[],
): {
  affordable: CardCostTuple[];
} {
  // XXX: this function only accounts for gold, not other resources

  const baseCounts = Rules.computeBaseCounters(context);

  let card_cost_tuples: CardCostTuple[] = cards.map((card) => {
    let cost = Math.max(0, card.cost - baseCounts.gold);
    return [card, cost];
  });

  const affordable = card_cost_tuples.filter(([_, cost]) => {
    return cost <= context.player.counters.gold;
  });

  return { affordable };
}

export function endOfGameEffectsFavor(context: Rules.Context): {
  favor: number;
  triggeredCardIDs: string[];
  context: Rules.Context;
} {
  const initial_favor_delta = context.production_delta.favor;
  let newContext = Rules.handleEndOfGameEffects(context);
  return {
    favor: newContext.production_delta.favor - initial_favor_delta,
    triggeredCardIDs: newContext.triggeredCardIDs,
    context: newContext,
  };
}

// expects a game in the planning phase
export function resultsOfGainingCard(
  gameState: InflatedGame,
  player: InflatedPlayer,
  card: Rules.CardWithID,
  bid_military: number,
): {
  baseDelta: CounterDelta;
  productionDelta: CounterDelta;
  totalCounterDelta: CounterDelta;
  completedTributeIDs: string[];
  tributeFavor: number;
} {
  let initialContext = Rules.makeContext(
    player,
    gameState.turn,
    gameState.age,
    gameState.options,
  );
  const initialBase = Rules.computeBaseCounters(initialContext);
  initialContext = Rules.handleEndOfGameEffects(initialContext);
  let initialCounters = addCounters(
    initialContext.player.counters,
    initialContext.production_delta,
  );
  // this must happen after initialCounters is computed as we don't want to include a production round
  const initialProduction = totalProductionCounters(initialContext);

  // Speculative tribute data
  // XXX: the speculative tribute data is incorrectly recorded as it expects a
  // fresh context for each phase but we accumulate a single speculative context
  const productionData: Record<string, TributeLogicProductionData> =
    gameState.productionDataByPlayerID[player.userID] ?? {};
  const planningData: Record<string, TributeLogicPlanningData> =
    gameState.planningDataByPlayerID[player.userID] ?? {};
  const resolutionData: Record<string, TributeLogicResolutionData> = {};

  const speculativePlayer = {
    ...player,
    counters: {
      ...player.counters,
    },
    bid: player.bid ?? {
      tradeRowIndex: gameState.table.findIndex((c) => c.id === card.id),
      military: bid_military,
    },
    cards: [...player.cards],
  };

  // planning
  if (gameState.phase === Phases.PLANNING) {
    let planningContext = Rules.makeContext(
      speculativePlayer,
      gameState.turn,
      gameState.age,
      gameState.options,
    );
    planningContext = Rules.handleBid(planningContext, speculativePlayer.bid);

    Rules.resolveContextCounters(planningContext);

    // conflict

    gameState.tributeRow.forEach((tribute) => {
      const tributeLogic = getTributeLogic(tribute);
      if (!tributeLogic) {
        return;
      }

      planningData[tribute.id] = tributeLogic.planningDataFunction(
        planningContext,
        {
          players: gameState.players,
          results: {
            winningPlayerID: player.userID,
            rounds: [],
            participantPlayerIDs: [player.userID],
          },
        },
      );
    });
  }

  // resolution
  let resolutionContext = Rules.makeContext(
    speculativePlayer,
    gameState.turn,
    gameState.age,
    gameState.options,
  );
  resolutionContext = Rules.handleResolutionSelection(
    resolutionContext,
    card,
    card,
  );

  speculativePlayer.cards.push(card);
  resolutionContext = Rules.handleGainCard(resolutionContext, card);

  Rules.resolveContextCounters(resolutionContext);
  gameState.tributeRow.forEach((tribute) => {
    const tributeLogic = getTributeLogic(tribute);
    if (!tributeLogic) {
      return;
    }

    resolutionData[tribute.id] = tributeLogic.resolutionDataFunction(
      resolutionContext,
      gameState.table,
      card,
    );
  });

  // tributes
  let tributeContext = Rules.makeContext(
    speculativePlayer,
    gameState.turn,
    gameState.age,
    gameState.options,
  );

  // PERF: this should early out when it sees events with the wrong age/turn
  const conflictResultsEvent =
    (findReverse(gameState.events, (event) => {
      return (
        event.age === gameState.age &&
        event.turn === gameState.turn &&
        event.type === EventTypes.CONFLICT_RESULTS &&
        event.payload.rounds[0][player.userID] != null
      );
    }) as GameEventOfType<EventTypes.CONFLICT_RESULTS> | null) || null;

  tributeContext = Rules.handleTributeGain(
    tributeContext,
    gameState.tributeRow,
    planningData,
    resolutionData,
    productionData,
    gameState.players,
    conflictResultsEvent,
  );
  Rules.resolveContextCounters(tributeContext);

  // compute output
  let finalContext = Rules.makeContext(
    speculativePlayer,
    gameState.turn,
    gameState.age,
    gameState.options,
  );

  const finalBase = Rules.computeBaseCounters(finalContext);
  finalContext = Rules.handleEndOfGameEffects(finalContext);
  const finalCounters = addCounters(
    finalContext.player.counters,
    finalContext.production_delta,
  );
  const finalProduction = totalProductionCounters(finalContext);

  return {
    baseDelta: subtractCounterDeltas(finalBase, initialBase),
    productionDelta: subtractCounterDeltas(finalProduction, initialProduction),
    totalCounterDelta: subtractCounterDeltas(finalCounters, initialCounters),
    completedTributeIDs: tributeContext.completedTributeIDs,
    tributeFavor: tributeContext.tribute_favor,
  };
}

export type CardUpgradeInfo = {
  abilityAndConditionPairs: [AbilityUpgradeCondition, BaseCardAbility][];
};
export type UpgradeInfo = {
  cardIDToLockedUpgradeInfo: Map<string, CardUpgradeInfo>;
};
export function computeUpgradeInfo(
  player: InflatedPlayer,
  game: InflatedGame,
): UpgradeInfo {
  const context = makeContextFromGame(player, game);
  const cardIDToLockedUpgradeInfo = new Map<string, CardUpgradeInfo>();

  for (const card of player.cards) {
    for (const ability of getAllCardAbilities(card)) {
      for (const output of ability.outputs) {
        const upgradeCondition = output.upgradeCondition;
        if (!upgradeCondition) {
          continue;
        }
        if (!upgradeConditionMet(upgradeCondition, context)) {
          const existing = cardIDToLockedUpgradeInfo.get(card.id);
          if (existing) {
            existing.abilityAndConditionPairs.push([upgradeCondition, ability]);
          } else {
            cardIDToLockedUpgradeInfo.set(card.id, {
              abilityAndConditionPairs: [[upgradeCondition, ability]],
            });
          }
          break;
        }
      }
    }
  }
  return {
    cardIDToLockedUpgradeInfo,
  };
}

export function calculatePartialUpgradeOutputGain(
  card: Rules.CardWithID,
  player: InflatedPlayer,
  game: InflatedGame,
): {
  baseDelta: CounterDelta;
  productionDelta: CounterDelta;
  totalCounterDelta: CounterDelta;
} {
  const current_context = Rules.makeContextFromGame(player, game);
  const speculativePlayer = { ...player, cards: [...player.cards] };
  const speculative_context = makeContextFromGame(speculativePlayer, game);
  speculativePlayer.cards.push(card);
  const upgradeInfo = computeUpgradeInfo(speculativePlayer, game);
  const baseDelta = makeCounterDelta();
  const productionDelta = makeCounterDelta();
  const totalCounterDelta = makeCounterDelta();
  upgradeInfo.cardIDToLockedUpgradeInfo.forEach((cardUpgradeInfo, cardID) => {
    if (cardUpgradeInfo) {
      for (const [
        condition,
        ability,
      ] of cardUpgradeInfo.abilityAndConditionPairs) {
        ability.outputs.forEach((output) => {
          if (!output.upgradeCondition) {
            return;
          }

          const upgrade_target = condition.upgradeAmount;
          const upgrade_count = computeOutputMultiplierForPlayer(
            condition.upgradeType,
            speculative_context,
          );

          // if the target is met, then the upgrade is already accounted for
          if (upgrade_count >= upgrade_target) {
            return;
          }
          let added_value = 0;
          if (cardID === card.id) {
            added_value =
              computeOutputMultiplierForPlayer(
                condition.upgradeType,
                speculative_context,
              ) / upgrade_target;
          } else if (condition.upgradeType) {
            added_value =
              (computeOutputMultiplierForPlayer(
                condition.upgradeType,
                speculative_context,
              ) -
                computeOutputMultiplierForPlayer(
                  condition.upgradeType,
                  current_context,
                )) /
              upgrade_target;
          } else {
            // card does not contribute to speculative card's upgrade condition
            return;
          }

          added_value *= output.outputRatio;
          if (output.multiplier) {
            added_value *=
              computeOutputMultiplierForPlayer(
                output.multiplier.resource,
                speculative_context,
              ) / output.multiplier.resourceDivisor;
          }
          match(ability)
            .with(
              P.instanceOf(CardAbilities.BaseCountersAbility),
              () => baseDelta,
            )
            .with(
              P.instanceOf(CardAbilities.EachTurnAbility),
              () => productionDelta,
            )
            .with(
              P.instanceOf(CardAbilities.EndOfGameAbility),
              () => totalCounterDelta,
            )
            .otherwise(() => {
              invariant(false, 'unhandled ability type');
            })[output.outputResource] += added_value;
        });
      }
    }
  });
  return {
    baseDelta,
    productionDelta,
    totalCounterDelta,
  };
}

export type CardValueData = {
  card: Rules.CardWithID;
  cost: number;
  value: number;
  bid: number;
};
export function calculateCardValues(
  game_state: InflatedGame,
  player_id: string,
  cards: Rules.CardWithID[],
  preferences: CardValuePreferences,
): CardValueData[] {
  const players = game_state.players;
  const player = nullthrows(
    game_state.players.find((player) => player.userID === player_id),
  );
  const context = Rules.makeContextFromGame(player, game_state);
  const player_total_counters = addCounters(
    player.counters,
    Rules.computeBaseCounters(context),
  );
  const total_output_by_userID: Record<string, CounterDelta> = {};
  const player_card_type_counts = cardCompositionCounts(player);

  players.forEach((player) => {
    const context = Rules.makeContextFromGame(player, game_state);
    total_output_by_userID[player.userID] = addCounters(
      totalProductionCounters(context),
      Rules.computeBaseCounters(context),
    );
  });

  const max_opponent_military_output = _.max(
    players.map((opponent) => {
      if (opponent.userID != player_id) {
        return total_output_by_userID[opponent.userID].military;
      }
    }) as number[],
  );
  const target_military_output =
    max_opponent_military_output + preferences.military_supremacy_target;

  let card_value_data_array: CardValueData[] = cards.map((card) => {
    const initial_results = resultsOfGainingCard(
      game_state,
      player,
      card,
      // TODO: determine bid
      0,
    );

    // Factor in upgrades
    let partial_upgrade_outputs = calculatePartialUpgradeOutputGain(
      card,
      player,
      game_state,
    );
    debug_log(
      `${player.userID}: [${card.name}] partial upgrade outputs: ${JSON.stringify(
        partial_upgrade_outputs,
      )}`,
    );

    const new_base_delta = addCounters(
      initial_results.baseDelta,
      partial_upgrade_outputs.baseDelta,
    );
    const new_production_delta = addCounters(
      initial_results.productionDelta,
      partial_upgrade_outputs.productionDelta,
    );
    const new_total_delta = addCounters(
      initial_results.totalCounterDelta,
      partial_upgrade_outputs.totalCounterDelta,
    );
    const results = {
      baseDelta: new_base_delta,
      productionDelta: new_production_delta,
      totalCounterDelta: new_total_delta,
      completedTributeIDs: initial_results.completedTributeIDs,
      tributeFavor: initial_results.tributeFavor,
    };

    let value = results.totalCounterDelta.favor - results.tributeFavor;
    debug_log(
      `${player.userID}: [${card.name}] yields favor ${value} for acquiring`,
    );

    // Factor in card value, then add tributes
    value *= preferences.card_flat_favor_preference_by_age[game_state.age - 1];

    // Factor in the cost of the card
    if (player_total_counters.gold < card.cost) {
      value -= preferences.unaffordable_value_penalty;
      debug_log(
        `${player.userID}: [${card.name}] cant afford card (${player.counters.gold} < ${card.cost}) reducing value by ${preferences.unaffordable_value_penalty}`,
      );
    }

    const cost_deduction =
      preferences.card_cost_value_reduction_by_age[game_state.age - 1] *
      card.cost;
    value -= cost_deduction;
    debug_log(
      `${player.userID}: [${card.name}] cost deduction to value ${cost_deduction}`,
    );

    value += preferences.tribute_value * results.tributeFavor;

    if (preferences.random_value_range) {
      value += randomFloatRange(
        preferences.random_value_range[0],
        preferences.random_value_range[1],
      );
    }
    // Factor in the outputs of the card
    // TODO: consider moving to helper function
    // TODO: weight base and production outputs based on turn/age and player current base + production
    let value_from_outputs = 0;

    let card_total_gold_output =
      results.baseDelta.gold + results.productionDelta.gold;
    let card_total_military_output =
      results.baseDelta.military + results.productionDelta.military;

    value_from_outputs +=
      card_total_gold_output *
      preferences.card_output_values_by_age['gold'][game_state.age - 1];
    value_from_outputs +=
      card_total_military_output *
      preferences.card_output_values_by_age['military'][game_state.age - 1];

    // add value for reaching target gold output
    if (
      total_output_by_userID[player.userID].gold <
        preferences.target_gold_output_by_age[game_state.age - 1] &&
      card_total_gold_output > 0
    ) {
      let value_add_for_target_gold =
        card_total_gold_output *
        (preferences.target_gold_output_by_age[game_state.age - 1] -
          total_output_by_userID[player_id].gold);
      debug_log(
        `${player.userID}: [${card.name}] value for approaching target gold output ${value_add_for_target_gold}`,
      );
      value += value_add_for_target_gold;
    }

    // add value for reaching target military output
    if (
      total_output_by_userID[player.userID].military < target_military_output &&
      card_total_military_output > 0
    ) {
      let value_add_for_target_military =
        card_total_military_output *
        preferences.military_supremacy_preference *
        (target_military_output - total_output_by_userID[player_id].military);
      debug_log(
        `${player.userID}: [${card.name}] value for approaching target military output ${value_add_for_target_military}`,
      );
      value += value_add_for_target_military;
    }

    // XXX(zack): should this be a different weight?
    const card_ongain_outputs =
      CardAbilities.getGainSelfCardAbility(card)?.onGainSelf(context);
    value_from_outputs +=
      (card_ongain_outputs?.gold ?? 0) *
      preferences.card_output_values_by_age['gold'][game_state.age - 1];
    value_from_outputs +=
      (card_ongain_outputs?.military ?? 0) *
      preferences.card_output_values_by_age['military'][game_state.age - 1];

    value += value_from_outputs;
    debug_log(
      `${player.userID}: [${card.name}] value from outputs ${value_from_outputs}`,
    );

    const card_type = card.affinity;
    if (
      player_card_type_counts[card_type] <
      preferences.target_card_type_by_age[card_type][game_state.age - 1]
    ) {
      let value_add_for_target_card_type =
        preferences.target_card_type_by_age[card_type][game_state.age - 1] -
        player_card_type_counts[card_type];
      debug_log(
        `${player.userID}: [${card.name}] value for approaching target card type count ${value_add_for_target_card_type}`,
      );
      value += value_add_for_target_card_type;
    }

    let cost = card.cost;
    return { card, cost, value, bid: player.counters.military };
  });

  card_value_data_array = _.sortBy(
    card_value_data_array,
    (data) => -data.value,
  ); //- for desc
  debug_log(`${player_id}: card_value_tuples: `);
  debug_log(
    card_value_data_array.map((data) => {
      return [data.value, data.card.name];
    }),
  );

  return card_value_data_array;
}

export function calculateBidFromCardValueThresholds(
  card_value_data: CardValueData,
  preferences: BidPreferences,
  context: Rules.Context,
): number {
  const low_threshold = preferences.low_value_threshold_by_age[context.age - 1];
  const high_threshold =
    preferences.high_value_threshold_by_age[context.age - 1];
  let bid = 0;
  const forts = Rules.computeBaseCounters(context).military;
  const min_bid = forts;
  const max_bid = forts + context.player.counters.military;
  if (card_value_data.value >= high_threshold) {
    bid = forts + context.player.counters.military;
  } else if (card_value_data.value > low_threshold) {
    // lerp bid if between the thresholds
    bid =
      min_bid +
      ((max_bid - min_bid) * (card_value_data.value - low_threshold)) /
        (high_threshold - low_threshold);
  } else {
    bid = min_bid;
  }

  // add randomness if specified
  if (preferences.random_value_range) {
    bid += randomFloatRange(
      preferences.random_value_range[0],
      preferences.random_value_range[1],
    );
  }
  bid = Math.round(bid);
  return clamp(bid, min_bid, max_bid);
}
