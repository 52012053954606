/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import React from 'react';
import Session from '../common/utils/Session';
import LogoBanner from './LogoBanner';
import PageHeaderView from './PageHeaderView';

export default function PageChrome(props: {
  children: React.ReactNode;
  session?: Session;
}) {
  const { children, session } = props;

  return (
    <div css={PageLayoutStyles.container}>
      <PageHeaderView session={session} />
      <LogoBanner />
      <div css={PageLayoutStyles.content}>{children}</div>
      <footer css={PageLayoutStyles.footer}>
        &copy; 2015-2025 Dapper Sloth Games, LLC. All rights reserved.
      </footer>
    </div>
  );
}

const PageLayoutStyles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }),
  content: css({
    flex: 1,
  }),
  footer: css({
    textAlign: 'center',
    padding: 12,
    // color: '#888',
  }),
};
