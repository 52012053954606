import { CardAffinity } from '@mythos/game/CardTypes';

export const CardTypeToFrameColor: Record<CardAffinity, string> = {
  [CardAffinity.Basic]: 'rgb(114, 75, 40)',
  [CardAffinity.Derion]: 'rgb(60, 121, 60)',
  [CardAffinity.Tyros]: 'rgb(202, 88, 62)',
  [CardAffinity.Zeona]: 'rgb(73, 127, 177)',
  [CardAffinity.Leader]: 'rgb(199, 199, 199)',
  [CardAffinity.None]: 'rgb(110, 110, 110)',
};

export const CardTypeToEffectBGStyle = {
  [CardAffinity.Basic]: {
    backgroundColor: 'rgba(230, 213, 191, 0.8)',
  },
  [CardAffinity.Derion]: {
    backgroundColor: 'rgba(202, 227, 190, 0.8)',
  },
  [CardAffinity.Tyros]: {
    backgroundColor: 'rgba(239, 200, 191, 0.8)',
  },
  [CardAffinity.Zeona]: {
    backgroundColor: 'rgba(192, 219, 232, 0.8)',
  },
  [CardAffinity.Leader]: {
    backgroundColor: 'rgba(199, 199, 199, 0.8)',
  },
  [CardAffinity.None]: {
    backgroundColor: 'rgba(180, 180, 180, 0.8)',
  },
};
