/** @jsx jsx */
/** @jsxFrag */
import { css, jsx } from '@emotion/react';
import {
  CardAffinity,
  CardType,
  getCardAffinityString,
  getCardTypeString,
  isCardAffinity,
  isCardType,
} from '@mythos/game/CardTypes';
import {
  AGES_PER_GAME,
  MAX_GOLD,
  MAX_MILITARY,
  TURNS_PER_AGE,
} from '@mythos/game/GameConstants';
import { getDefaultGameOptions } from '@mythos/game/GameOptions';
import React, { ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { sample } from 'underscore';
import Session from '../common/utils/Session';
import CardView, { BasicCardRenderer } from './CardView';
import HoverCardStore from './HoverCardStore';
import Symbols, {
  CardAffinityToSymbol,
  CardTypeToSymbol,
  SymbolView,
} from './Symbols';
import { BasicTributeRenderer, TRIBUTE_TYPE_TO_IMAGE } from './TributeCardView';

import BaseBoardExample from '../assets/BaseBoardExample.svg';
import BidderExample from '../assets/BidderExample.svg';
import DiceExample from '../assets/DiceExample.svg';
import Favor100Example from '../assets/Favor100Example.svg';
import FavorTrackerExample from '../assets/FavorTrackerExample.svg';
import GoldTokenExample from '../assets/GoldTokenExample.svg';
import MilitaryTokenExample from '../assets/MilitaryTokenExample.svg';
import RulesCardAnatomy from '../assets/RulesCardAnatomy.svg';
import TributeCardAnatomy from '../assets/RulesTributeCardAnatomy.svg';
import Reroll from '../assets/symbols/reroll.svg';

import { GameDefinitions } from '@mythos/game/GameModel';
import { probabilityWinningMultiBattle } from '@mythos/game/Probability';
import { endOfGamePointsPerToken } from '@mythos/game/Rules';
import invariant from 'invariant';
import { MEDIA_QUERY_MOBILE } from 'mobile_ui/MStyles';
import { match } from 'ts-pattern';
import _ from 'underscore';
import BaseBoardFilledExample from '../assets/BaseBoardFilledExample.svg';
import ConflictBidderExample from '../assets/ConflictBidderExample.svg';
import GameBoardAnatomy from '../assets/GameBoardAnatomy.jpg';
import PlayerBoardExample from '../assets/PlayerBoardExample.jpg';
import { fetchGameDefinitions } from './actions';
import { CardBackType, CardBackView } from './CardBackView';

function RulesPage(props: {
  dispatch: (action: any) => void;
  session?: Session;
  gameDefinitions: GameDefinitions | null;
}) {
  const { dispatch, session, gameDefinitions } = props;

  useEffect(() => {
    dispatch(fetchGameDefinitions());
  }, []);

  useEffect(() => {
    if (gameDefinitions) {
      // scroll to hash
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView();
        }
      }
    }
  }, [gameDefinitions]);

  if (!gameDefinitions) {
    return <div className="loading">Loading...</div>;
  }
  const cards = gameDefinitions.cardDefs;
  const tributeCards = gameDefinitions.tributeCardDefs;

  // Overview section
  const overview_section = (
    <PageSection title="Overview" linkName="overview">
      <p css={RulesPageStyles.section_paragraph}>
        In a distant land of fiercely warring city-states, many seek the favor
        of the gods to survive. Compete with other players to gather land,
        develop your city into a booming polis, and appease the gods so that
        they may bless your mortal existence. In time, your victories may carve
        your story into the legends of Mythos.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        The game is divided into {AGES_PER_GAME} ages of {TURNS_PER_AGE} turns.
        During a turn, players will plan drafts and mobilize their military to
        grow their empire. As they do, they will curry Favor with the Gods by
        completing Tributes. At the end of the game, the player who has gathered
        the most Favor wins the game.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        Mythos is a card-drafting game based around simultaneous actions.
        Players should hide their actions for a given phase of the game until
        all players have made their decisions. Once everyone is ready, players
        reveal their decisions and take the appropriate actions.
      </p>
    </PageSection>
  );

  // Resources section
  const resources = [
    {
      name: 'gold',
      symbol: Symbols.GOLD,
      description: `Gold is used to obtain cards. You can only have up to ${MAX_GOLD} Gold.`,
    },
    {
      name: 'military',
      symbol: Symbols.MILITARY,
      description: `Might helps you compete for cards by adding to your dice rolls. You can only have up to ${MAX_MILITARY} Might.`,
    },
    {
      name: 'favor',
      symbol: Symbols.FAVOR,
      description:
        'Favor is gained by obtaining cards and completing tributes. At the end of the game, the player with the most favor wins.',
    },
    {
      name: 'towns',
      symbol: Symbols.BASE_GOLD,
      description:
        'Towns reduce the amount of Gold you need to pay when obtaining cards by 1 Gold per Town.',
    },
    {
      name: 'forts',
      symbol: Symbols.BASE_MILITARY,
      description:
        'Forts reduce the amount of Might you need to pay when bidding by 1 Might per Fort.',
    },
  ];
  const resources_section = (
    <PageSection title="Resources" linkName="resources">
      <ul css={RulesPageStyles.list}>
        {resources.map((resource) => (
          <li key={resource.name} css={RulesPageStyles.listItemSymbolBullet}>
            <SymbolView symbol={resource.symbol} css={RulesPageStyles.symbol} />
            {resource.description}
          </li>
        ))}
      </ul>
    </PageSection>
  );

  // Card Outputs section
  type CardOutputSection = {
    name?: string;
    example: string;
    description: string;
  };
  const card_outputs: CardOutputSection[] = [
    {
      name: 'Building Effects',
      example: 'Farm',
      description:
        'grant Towns and Forts, which passively grant discounts on the Gold and Might you spend. So, if you purchase a card that costs 5 Gold and have 2 Towns, you only need to spend 3 Gold to purchase it. When bidding, if you bid 6 and have 1 Fort, you only need to spend 5 Military. This grants 1 Town.',
    },
    {
      name: 'Production Effects',
      example: 'Warrior',
      description:
        'grant Gold and Might during the Production phase of each turn. This effect grants 1 Might during each Production phase.',
    },
    {
      name: 'Obtain Effects',
      example: 'Trade Deal',
      description:
        'grant tokens when the card is placed onto your board. This effect grants 3 Gold and 2 Might upon obtaining the card.',
    },
    {
      name: 'Upgrade Effects',
      example: 'Soldier of Tyros',
      description:
        'grant additional effects if a certain criteria is met. This upgrade effect grants 1 Might at the start of each turn, plus an additional one if the player owns 3 Conflict cards (including itself).',
    },
    // {
    //   output: [
    //     {
    //       ability: CardAbilities.EachTurnAbility,
    //       params: 'M/2D',
    //     },
    //   ],
    //   description:
    //     'Gain 1 Military token for every two buildings you own at the start of each turn.',
    // },
    {
      name: 'Synergy Obtain Effects',
      example: 'Fortifications',
      description:
        'grant scaling effects based on cards the player already owns. The effect includes the obtained card itself. This effect grants 1 Might upon obtaining for every Building card the player owns, including itself.',
    },
    {
      name: 'Prophecy Effects',
      example: 'Oracle',
      description:
        'grant Favor at the end of the game based on the cards you own. This Prophecy effect grants 2 Favor for every Event card you own at the end of the game.',
    },

    // TODO: re-introduce in modules section
    // {
    //   output: [
    //     {
    //       ability: CardAbilities.GainOtherCardAbility,
    //       params: '+T=V',
    //     },
    //   ],
    //   description: 'Gain 1 Favor every time you complete a Tribute.',
    // },
    // {
    //   output: [
    //     {
    //       ability: CardAbilities.GainOtherCardAbility,
    //       params: '+T=G M V',
    //     },
    //   ],
    //   description:
    //     'Gain 1 Favor, Gold and Military every time you complete a Tribute.',
    // },
  ];

  const card_output_divs = card_outputs.map((card_output, i) => {
    let card = cards.find((card) => card.name === card_output.example);
    if (!card) {
      console.error('Could not find card: ' + card_output.example);
      return;
    }
    return (
      <span key={i} css={RulesPageStyles.section_card_outputs_container}>
        <div className="card-with-note">
          {BasicCardRenderer({
            card,
            onCardEnter: (card) =>
              HoverCardStore.setCard(card, BasicCardRenderer),
            onCardLeave: () => HoverCardStore.setCard(null, null),
          })}
        </div>
        <span css={RulesPageStyles.section_paragraph}>
          <span css={RulesPageStyles.subsection_title}>{card_output.name}</span>{' '}
          {card_output.description}
        </span>
      </span>
    );
  });
  const card_outputs_section = (
    <PageSection title="Card Outputs" linkName="card_outputs">
      Cards will generally grant an output represented by symbols on the card.
      Here are some example cards and what their abilities do:
      {card_output_divs}
    </PageSection>
  );

  // Gods section
  const gods = [
    {
      name: 'Zeona',
      image: TRIBUTE_TYPE_TO_IMAGE['Zeona'],
      description:
        "Zeona, the Goddess of Order, sits at the helm of the pantheon of Mythos. Zeona is said to keep the other Gods in check and controlling the balance of power in Mythos. She is often depicted as a fierce, yet graceful lioness wielding a hammer. In the days before the city-states, humans were barbaric and chaotic. Zeona blessed humans with ideals of order and structure, and from this, the polis was born. Zeona's worshippers often create decadent temples in her name, and pray for wealth, comfort, and protection against the otherwise chaotic world.",
    },
    {
      name: 'Tyros',
      image: TRIBUTE_TYPE_TO_IMAGE['Tyros'],
      description:
        'Tyros, the God of Conflict, loves nothing more to witness the struggle and bloodshed of mortals as they combat each other. Tyros is often depicted as a menacing bull-centaur brandishing a spear. Worshippers of Tyros give little regard for their own life, as to die in battle is to die in the glory of Tyros. As such, they are fierce and relentless, and are regarded as some of the strongest fighters in the world.',
    },
    {
      name: 'Derion',
      image: TRIBUTE_TYPE_TO_IMAGE['Derion'],
      description:
        "Derion, the God of Plenty, bestows upon the world great harvest and bounty. Derion is Tyros' brother, and often admonishes his aggression. He favors those who labor in the fields, and rewards their hard work with bountiful output. Derion is oftentimes depicted as a great ox-centaur, but many say he takes human form during the harvest moon to work alongside people. Worshippers of Derion are often in tune with nature, preferring the fields of a farm to the city bustle.",
    },
  ];
  const gods_section = (
    <PageSection title="Lore of the Game: The Gods" linkName="lore">
      {gods.map((god) => {
        return (
          <PageSection key={god.name} title={god.name}>
            <div
              css={[
                RulesPageStyles.section_with_image,
                RulesPageStyles.section_with_image_mirrored,
                RulesPageStyles.section_with_image_narrow,
              ]}
            >
              <img src={god.image} css={RulesPageStyles.image} />
              <p css={RulesPageStyles.section_paragraph}>{god.description}</p>
            </div>
          </PageSection>
        );
      })}
    </PageSection>
  );

  type PhaseSection = {
    name: string;
    description: ReactNode;
    exampleImage?: string;
    exampleCaption?: string;
  };
  // Core rules section
  const phases: PhaseSection[] = [
    {
      name: 'Setup',
      description: (
        <>
          Cards are drawn from the current age's deck and dealt to the Trade
          Row, right to left, until the Trade Row has cards equal to the number
          of players plus one. Tribute Cards are dealt to the Tribute Row until
          the Tribute Row has 3 cards. If the Tribute Deck is ever empty, the
          discarded Tributes are reshuffled into the Tribute Deck.
        </>
      ),
    },
    {
      name: 'Production',
      description: (
        <>
          Each player gains Might and Gold from their card Production effects.
        </>
      ),
      exampleImage: PlayerBoardExample,
      exampleCaption:
        'Example: The player gains 1 Gold and 2 Might. The Mill and Worship cards do not contribute to production.',
    },
    {
      name: 'Planning',
      description: (
        <>
          During this phase, each player selects a Trade Row Card and a number
          of Might to bid. Both of these choices are made in secret. A player
          may bid up to their current Might count plus the number of Forts they
          have. Since Forts discount the amount of Might spent, a player's
          minimum bid will be set to the number of Forts they have.
        </>
      ),
      exampleImage: BidderExample,
      exampleCaption:
        'Example: The purple player has selected the second card in the Trade Row and bid 3 Might.',
    },
    {
      name: 'War',
      description: (
        <>
          Players' Trade Row selections and bids are revealed. Players spend
          Might equal to their bid minus the number of Forts they have. If
          multiple players draft the same card, they are in a clash over the
          card.
          <br />
          <br />
          Each player in a clash rolls a number of six-sided die equal to the
          age, and adds their their bid. The winner is the player with the final
          highest total.
          <br />
          <br />
          If two or more players tie for the highest total, those players reroll
          until only one player has the highest total. Other players within the
          clash are eliminated from contention.
        </>
      ),
      exampleImage: ConflictBidderExample,
      exampleCaption:
        'Example: The purple and green players have selected the same card. The purple player bid 3 Might and rolled a 2 for a total of 5, while the green player bid 1 Might and rolled a 3, for a total of 4. The purple player has defeated the green player.',
    },
    {
      name: 'Resolution',
      description: (
        <>
          For each drafted card, the winner of the clash over that card can now
          obtain the card. If the card was drafted by only one player, then that
          player may obtain the card. To obtain a card, the player spends Gold
          equal to the cost of the card minus the number of Towns they have.
          <br />
          <br />
          If the winning player cannot afford the card, or they do not wish to
          obtain it, they must take a Basic card. Players who lost a clash must
          take a Basic card.
          <br />
          <br />
          Upon obtaining a card, players gain the favor listed on the card, as
          well as any resources from the card's Obtain Effects.
        </>
      ),
    },
    {
      name: 'Tributes',
      description: (
        <>
          If a player have met the Completion Condition for a Tribute, taking
          into account the results of their actions this turn, they gain the
          Favor value listed on that Tribute.
          <br />
          <br />
          Some Tributes have a variable condition Value per Age. You must meet
          the condition for the current age to complete the Tribute.
          <br />
          <br />
          If multiple players have completed a Tribute, all of those players
          gain the tribute's favor.
          {/* When a tribute
          is completed the completing players resolve their cards' On Tribute
          Gain effect. */}
        </>
      ),
    },
    {
      name: 'Cleanup',
      description: (
        <>
          Remove all completed tributes from the Tribute Row. Slide all
          remaining cards on the Trade Row to the right until they occupy the
          highest-numbered slots for the number of players in the game. In a two
          player game, instead discard all cards in the Trade Row.
          <br />
          <br />
          If it is the 5th turn of the Age, proceed to the end of the age.
          Otherwise, advance the Turn Tracker.
        </>
      ),
    },
  ];
  const phase_divs = phases.map((phase) => {
    return (
      <p key={phase.name} css={RulesPageStyles.section_paragraph}>
        <span css={RulesPageStyles.subsection_title}>{phase.name}</span>:{' '}
        {phase.description} <br />
        {phase.exampleImage && (
          <img
            src={phase.exampleImage}
            css={RulesPageStyles.imagePhaseExample}
          />
        )}
        <span css={RulesPageStyles.imageCaption}>{phase.exampleCaption}</span>
      </p>
    );
  });
  const eogPoints = endOfGamePointsPerToken(getDefaultGameOptions());
  const core_rules_section = (
    <div>
      <PageSection title="Game Setup" linkName="setup">
        Each player begins the game with a Leader card and gains resources from
        the Leader card's Obtain effect.
      </PageSection>
      <PageSection title="Start of Age" linkName="age-setup">
        Each age has an associated deck of Trade Row cards. Shuffle the deck for
        the age and the Tribute deck. Place all Basic cards for the age in the
        corresponding Basic Piles.
      </PageSection>
      <PageSection
        title="The Turn"
        linkName="turn"
        css={css({ breakInside: 'auto' })}
      >
        The turn is divided into several phases. Only Planning and Resolution
        require player input:
        {phase_divs}
      </PageSection>
      <PageSection title="End of Age" linkName="age-end">
        Remove all cards from the Trade Row, Tribute Row, and Basic Piles.
        Remove the Trade Row Deck from the board. Players keep all of their
        cards and resources. Advance to the next age.
      </PageSection>
      <PageSection title="Game End" linkName="game-end">
        At the end of the game, players will score favor based on their
        remaining resources and Prophecy Effects. 1 Favor is awarded for every{' '}
        {eogPoints.gold} Gold and every {eogPoints.military} Might. Calculate
        Favor gained from Prophecy Effects and add that to the final Favor
        total. The player with the most favor wins the game.
      </PageSection>
      {/* TODO: Example of end of game scoring */}
    </div>
  );

  type CardTypeDescription = {
    type: CardAffinity | CardType;
    description: string;
    example: string;
  };
  // Card types section
  const card_affinities: CardTypeDescription[] = [
    {
      type: CardAffinity.Derion,
      description:
        ' cards primarily grant Gold and Towns. Derion cards are green.',
      example: 'Marketplace',
    },
    {
      type: CardAffinity.Tyros,
      description:
        ' cards primarily grant Might and Forts. Tyros cards are red.',
      example: 'Warrior',
    },
    {
      type: CardAffinity.Zeona,
      description: ' cards primarily grant Favor. Zeona cards are blue.',
      example: 'Divine Blessing',
    },
  ];
  const card_types: CardTypeDescription[] = [
    {
      type: CardType.Building,
      description:
        ' cards grant Building Effects. These effects passively grant discounts on the Gold and Might you spend.',
      example: 'Farm',
    },
    {
      type: CardType.Unit,
      description:
        ' cards grant Production Effects. These effects grant you Gold and Might every turn.',
      example: 'Spearman',
    },
    {
      type: CardType.Event,
      description:
        ' cards grant Gold and Might, but only through Obtain Effects. They do not have any Production or Building effects.',
      example: 'Festivities',
    },
  ];
  const other_cards: CardTypeDescription[] = [
    {
      type: CardAffinity.Leader,
      description:
        ' cards are the cards each player starts with at the beginning of the game. They determine what resources a player begins with, as well as their initial production. Leader cards are gray.',
      example: 'Leader',
    },
    {
      type: CardAffinity.Basic,
      description:
        ' cards are not placed on the Trade Row. Instead, players take a Basic card whenever they lose a war or do not wish to obtain the card they drafted. Basic cards are brown.',
      example: 'Scavenge I',
    },
  ];
  const cardTypeDescriptionDiv = function (
    card_type_descriptions: CardTypeDescription,
  ) {
    const { is_type, symbol, card_type_name } = match(
      card_type_descriptions.type,
    )
      .when(
        (x) => isCardAffinity(x),
        (x) => {
          return {
            is_type: true,
            symbol: CardAffinityToSymbol[x],
            card_type_name: getCardAffinityString(x),
          };
        },
      )
      .when(
        (x) => isCardType(x),
        (x) => {
          return {
            is_type: true,
            symbol: CardTypeToSymbol[x],
            card_type_name: getCardTypeString(x),
          };
        },
      )
      .otherwise((x) => invariant(false, 'unknown card affinity/type: %s', x));
    let card = cards.find(
      (card) => card.name === card_type_descriptions.example,
    );
    if (!card) {
      console.error('Could not find card: ' + card_type_descriptions.example);
      card = sample(
        cards.filter((card) => {
          if (is_type) {
            return card.affinity === card_type_descriptions.type;
          }
          return card.type === card_type_descriptions.type;
        }),
      )!;
    }
    return (
      <div
        key={card_type_descriptions.type}
        css={RulesPageStyles.section_card_types}
      >
        <p>
          <span css={RulesPageStyles.subsection_title}>
            <SymbolView symbol={symbol} css={RulesPageStyles.symbol_inline} />
            {card_type_name}
          </span>
          {card_type_descriptions.description}
        </p>
        <div css={RulesPageStyles.card_with_note}>
          <CardView
            card={card}
            onCardEnter={(card) =>
              HoverCardStore.setCard(card, BasicCardRenderer)
            }
            onCardLeave={() => HoverCardStore.setCard(null, null)}
          />
        </div>
      </div>
    );
  };
  const card_type_divs = card_affinities.map((card_type) =>
    cardTypeDescriptionDiv(card_type),
  );
  const card_subtype_divs = card_types.map((card_type) =>
    cardTypeDescriptionDiv(card_type),
  );
  const other_card_divs = other_cards.map((card_type) =>
    cardTypeDescriptionDiv(card_type),
  );
  const card_types_section = (
    <PageSection title="Card Deities" linkName="card_types">
      <div css={RulesPageStyles.section_card_types_container}>
        {card_type_divs}
      </div>
    </PageSection>
  );
  const card_subtypes_section = (
    <PageSection title="Card Types" linkName="card_subtypes">
      <div css={RulesPageStyles.section_card_types_container}>
        {card_subtype_divs}
      </div>
    </PageSection>
  );
  const other_cards_section = (
    <PageSection title="Other Cards" linkName="card_subtypes">
      These cards will not appear on the Trade Row, but are obtained in other
      ways. Unless otherwise noted, these cards do not have a Deity, but they do
      have a Type.
      <div css={RulesPageStyles.section_card_types_container}>
        {other_card_divs}
      </div>
    </PageSection>
  );
  const set_note_section = (
    <div key="card_set" css={RulesPageStyles.section_paragraph}>
      <p>
        <span css={RulesPageStyles.subsection_title}>
          <SymbolView
            symbol={Symbols.CARD_SET}
            css={RulesPageStyles.symbol_inline}
          />
        </span>
        Some cards or tributes will refer to Card Sets, denoted by this symbol.
        This means a set of a Derion, Tyros, and Zeona card. For example, if you
        own 3 Derion Cards, 3 Tyros Cards, and 2 Zeona Cards, you have 2 Card
        Sets.
      </p>
    </div>
  );

  // Anatomy section
  const card_anatomy = [
    {
      name: 'Name',
      description: 'The name of the card. This has no effect on gameplay.',
    },
    {
      name: 'Cost',
      description: 'The Gold cost of the card.',
    },
    {
      name: 'Base Favor',
      description:
        'When you successfully obtain a card, you gain Favor equal to the value listed here.',
    },
    {
      name: 'Obtain Effect',
      description:
        'Some cards grant additional Gold, Might, or Favor when you obtain them.',
    },
    {
      name: 'Effect',
      description: "A card's Production and Building effects are listed here.",
    },
    {
      name: 'Type',
      description:
        'The type of the card. This can be Building, Unit, or Event. The card type generally describes how the card primarily grants resources.',
    },
    {
      name: 'Deity',
      description:
        'The Deity of the card. This can be Derion, Tyros, or Zeona. Basic and Leader card symbols also appear here for those cards instead of a Deity.',
    },
    {
      name: 'Age',
      description: 'The age in which it appears.',
    },
  ];
  const make_anatomy_divs = (anatomy: typeof card_anatomy) => {
    return anatomy.map((section, i) => {
      return (
        <li key={i}>
          <p css={RulesPageStyles.section_paragraph}>
            <span css={RulesPageStyles.subsection_title}>{section.name}</span>
            {section.description.length ? ': ' : ''}
            {section.description}
          </p>
        </li>
      );
    });
  };
  var card_anatomy_divs = make_anatomy_divs(card_anatomy);
  const card_anatomy_card =
    cards.find((card) => card.name === 'Tracker') || cards[0];
  const card_anatomy_section = (
    <PageSection title="Card Anatomy" linkName="card_anatomy">
      <div
        css={[
          RulesPageStyles.section_with_image,
          RulesPageStyles.section_with_image_mirrored,
        ]}
      >
        <div css={RulesPageStyles.card_anatomy_card}>
          {BasicCardRenderer({
            card: card_anatomy_card,
            overlayChildren: <img src={RulesCardAnatomy} />,
          })}
        </div>
        <ol css={RulesPageStyles.list_numbered}>{card_anatomy_divs}</ol>
      </div>
    </PageSection>
  );
  const tribute_anatomy = [
    {
      name: 'Name',
      description: 'The name of the Tribute. This has no effect on gameplay.',
    },
    {
      name: 'Favor',
      description:
        'When you complete a Tribute, you will gain Favor based on the age.',
    },
    {
      name: 'Completion Condition',
      description: 'How to complete the tribute.',
    },
    {
      name: 'Value per Age',
      description:
        'Some tributes have different numerical requirements based on the age.',
    },
  ];
  const tribute_anatomy_divs = make_anatomy_divs(tribute_anatomy);
  const tribute_anatomy_card =
    tributeCards.find((card) => card.name === 'Establish Bounty') ||
    tributeCards[0];
  const tribute_anatomy_section = (
    <PageSection title="Tribute Anatomy" linkName="tribute_anatomy">
      <div css={RulesPageStyles.section_with_image}>
        <div css={RulesPageStyles.card_anatomy_card}>
          {BasicTributeRenderer({
            card: tribute_anatomy_card,
            overlayChildren: (
              <img src={TributeCardAnatomy} style={{ height: '100%' }} />
            ),
          })}
        </div>
        <ol css={RulesPageStyles.list_numbered} style={{ paddingLeft: 10 }}>
          {tribute_anatomy_divs}
        </ol>
      </div>
    </PageSection>
  );
  const board_anatomy = [
    {
      name: 'Trade Row Deck',
      description: 'The deck of remaining Trade Row cards for the age.',
    },
    {
      name: 'Tribute Deck',
      description: 'The deck of remaining Tribute cards.',
    },
    {
      name: 'Tribute Discard Pile',
      description: 'Completed Tributes that have been discarded.',
    },
    {
      name: 'Basic Card Piles',
      description:
        'The three Basic Card types go into the corresponding piles here.',
    },
    {
      name: 'Tribute Row',
      description: 'The active Tributes.',
    },
    {
      name: 'Trade Row',
      description:
        'The set of Trade Row cards that players can select from. The number of cards in the Trade Row is equal to the number of players plus one. Always place the cards on the Trade Row from left to right.',
    },
    {
      name: 'Turn Tracker',
      description:
        'A tracker token is placed on this area to track the current turn of the age.',
    },
    {
      name: 'Age Tracker',
      description:
        'Tracks the age of the game. Place one die per player on each space. At the start of an age, give each player their die from the space corresponding to the age. The last uncovered space is the current age.',
    },
    {
      name: 'Favor Tracker',
      description:
        'Players use tracker tokens to count their favor around the board as the game progresses.',
    },
  ];
  const board_anatomy_divs = make_anatomy_divs(board_anatomy);
  const board_anatomy_section = (
    <PageSection title="Board Anatomy" linkName="board_anatomy">
      <img src={GameBoardAnatomy} css={RulesPageStyles.imageGameBoard} />
      <ol css={RulesPageStyles.list_numbered}>{board_anatomy_divs}</ol>
    </PageSection>
  );

  const player_board_anatomy_section = (
    <PageSection title="Player Board Example" linkName="player_board">
      <img src={BaseBoardFilledExample} css={RulesPageStyles.imageGameBoard} />
      <img src={PlayerBoardExample} css={RulesPageStyles.imagePlayerBoard} />
      <p css={RulesPageStyles.section_paragraph}>
        Players should use their Base Boards and the area above them to track
        their gold and military. The Base Board is used to track the player's
        base gold and military. The area above the Base Board is used to track
        the player's current gold and military tokens.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        In the above example, the purple player has 8 gold tokens and 7 base
        gold. This means that if they purchase a card that costs 10 gold, they
        will have to spend 3 gold tokens. The player also has 13 Military tokens
        and 1 Base Military. This means they can bid up to 13 Military tokens,
        and if they are in conflict, they will add 1 plus the number of tokens
        they bid to their dice roll.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        Players place the cards they obtain below their Base Boards. Cards
        should be arranged in columns based on their subtype (Unit, Building, or
        Event).
      </p>
    </PageSection>
  );

  const game_components_section_header = (
    <PageSection title="Game Components" linkName="components">
      {null}
    </PageSection>
  );
  type DeckItem = {
    name: string;
    description: string;
    type: CardBackType;
  };
  const decks: DeckItem[] = [
    {
      name: 'Age I',
      type: 'age1',
      description: 'The first age of the game.',
    },
    {
      name: 'Age II',
      type: 'age2',
      description: 'The second age of the game.',
    },
    {
      name: 'Age III',
      type: 'age3',
      description: 'The final age of the game.',
    },
    {
      name: 'Scavenge Cards',
      type: 'basicevent',
      description: 'The deck containing copies of the Scavenge Basic Card.',
    },
    {
      name: 'Settlement Cards',
      type: 'basicbuilding',
      description: 'The deck containing copies of the Settlement Basic Card.',
    },
    {
      name: 'Mercenary Cards',
      type: 'basicunit',
      description: 'The deck containing copies of the Mercenary Basic Card.',
    },
    {
      name: 'Tribute',
      type: 'tribute',
      description: 'The tribute deck.',
    },
  ];
  const decks_section = (
    <PageSection title="Decks" linkName="decks">
      <div css={RulesPageStyles.deck_section}>
        <div css={RulesPageStyles.decks_container}>
          {decks.map((deck, i) => (
            <div key={i} css={RulesPageStyles.deck_container}>
              <CardBackView
                card={{ id: `card-back-${deck.type}`, type: deck.type }}
              />
              {deck.name}
            </div>
          ))}
        </div>
      </div>
    </PageSection>
  );
  type GameComponent = {
    name: string;
    imageUrl: string;
    description: string;
    height?: number;
  };
  const misc_components: GameComponent[] = [
    {
      name: 'Gold Tokens',
      imageUrl: GoldTokenExample,
      description: `Gold is used to obtain cards. You can only have up to ${MAX_GOLD} Gold. There are also larger tokens to represent 5 Gold.`,
      height: 75,
    },
    {
      name: 'Military Tokens',
      imageUrl: MilitaryTokenExample,
      description: `Military helps you compete for cards by adding to your dice rolls. You can only have up to ${MAX_MILITARY} Military. There are also larger tokens to represent 5 Military.`,
      height: 75,
    },
    {
      name: '100 Favor Token',
      imageUrl: Favor100Example,
      description:
        'Once a player reaches 100 Favor, they take this token to track their total favor.',
    },
    {
      name: 'Bidder',
      imageUrl: BidderExample,
      description:
        'Used to secretly bid on cards. The top two dials determine the amount of Military tokens the player wishes to spend, and the bottom dial determines the Trade Row index of the card they wish to draft.',
      height: 150,
    },
    {
      name: 'Dice',
      imageUrl: DiceExample,
      description:
        'Used to resolve conflicts. Players roll a number of dice equal to the age of the card they are contesting.',
    },
    {
      name: 'Favor Trackers',
      imageUrl: FavorTrackerExample,
      description: 'Used to track Favor on the game board.',
    },
    {
      name: 'Base Board',
      imageUrl: BaseBoardExample,
      description:
        'Used to track Base Gold and Military. Whenever a player gains a card with a Base effect, they move the corresponding resource token onto the Base Board. The board has spaces for 1s and 5s tokens.',
      height: 200,
    },
    {
      name: 'Reroll Token',
      imageUrl: Reroll,
      description:
        'A player can use a reroll token during the War phase to reroll the dice they have rolled. They earn a reroll token if they ever lose a Clash in which they have bid among the most Military for the chosen card. A player can only have one Reroll token at a time.',
    },
  ];
  const misc_components_section = (
    <div css={RulesPageStyles.game_component_container}>
      {misc_components.map((component, i) => {
        return (
          <div key={i} css={RulesPageStyles.game_component}>
            <p css={RulesPageStyles.section_title_no_padding}>
              {component.name}
            </p>
            {/* <div css={RulesPageStyles.section_with_image}> */}
            <p css={RulesPageStyles.section_paragraph}>
              {component.description}
            </p>
            <img
              src={component.imageUrl}
              css={RulesPageStyles.imageExamples}
              style={{ height: component.height }}
            />
            {/* </div> */}
          </div>
        );
      })}
    </div>
  );
  const game_components_sections = [
    game_components_section_header,
    decks_section,
    misc_components_section,
    board_anatomy_section,
    player_board_anatomy_section,
  ];
  const playing_online_section = (
    <PageSection title="Playing Online" linkName="online">
      <p css={RulesPageStyles.section_paragraph}>
        To play Mythos Online, go to{' '}
        <a href="https://mythosgame.com">https://mythosgame.com</a>. Sign in
        with your Discord account, or log in as a guest. You can join a friend's
        game lobby, or create your own.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        Mythos Online handles the game rules, resources, and production for you.
        Each turn, you will interact during the Planning phase and Resolution
        phase.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        During the Planning phase, you must select a card from the Trade Row by
        left clicking on it. Cards you cannot afford are highlighted red. Your
        current selection is highlighted white. Then, set your bid by typing a
        number into the "Bid" input box. Once you have made your decision, hit
        the READY button and wait for all players to finish.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        During the Resolution Phase, all player decisions are revealed. On each
        card, you will see who selected that card, along with their bid. The
        star next to a player's name indicates they won the bid for the card.
        Dice rolls, results, and winning probabilities are displayed in the game
        log to the right.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        If you won the bid, the card will automatically highlight for you, and
        you can just hit Ready to obtain it. If you lost the bid, you need to
        select a Basic card to obtain. You may also opt to obtain a Basic card
        if you won the bid.
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        The Tributes section will highlight Tributes whose conditions you will
        meet with your current selections. Note that this does not take into
        account any hidden information, such as other players' decisions or dice
        rolls!
      </p>
      <p css={RulesPageStyles.section_paragraph}>
        While playing, you might find it useful to refer to the Player Summary
        View in the top right corner. This is a condensed version of each
        player's board. You can see each players' current Favor, Gold, Might,
        and the breakdown of card types and deities they own. Speculative points
        from Prophecy effects are shown in parentheses next to each Players's
        favor, if they have any. If you are uncertain about a section on the
        summary view, you can hover over it to see what it means.
      </p>
    </PageSection>
  );
  const war_probabilities_cells = _.map(_.range(0, 16), (bonus) => {
    let css = [RulesPageStyles.probability_table_cell];
    let cells = _.map(_.range(1, 4), (age) => {
      let p = probabilityWinningMultiBattle(age, bonus, [[age, 0]]);
      let pretty_p = Math.floor(Math.round(p * 100000) / 1000);
      if (bonus % 2 === 0) {
        css.push(RulesPageStyles.probability_table_cell_even);
      }
      return (
        <td key={age} css={css}>
          {bonus > 5 * age ? '' : pretty_p + '%'}
        </td>
      );
    });
    return (
      <tr key={bonus}>
        <td css={[css, RulesPageStyles.probability_table_cell_advantage]}>
          {bonus}
        </td>
        {cells}
      </tr>
    );
  });
  const war_probabilities_table = (
    <table css={RulesPageStyles.probability_table}>
      <thead>
        <tr>
          <th css={RulesPageStyles.probability_table_header}>Advantage</th>
          <th css={RulesPageStyles.probability_table_header}>Age I</th>
          <th css={RulesPageStyles.probability_table_header}>Age II</th>
          <th css={RulesPageStyles.probability_table_header}>Age III</th>
        </tr>
      </thead>
      <tbody>{war_probabilities_cells}</tbody>
    </table>
  );
  const war_probabilities_section = (
    <PageSection title="War Probabilities" linkName="war">
      <p css={RulesPageStyles.section_paragraph}>
        The table below shows the probability of winning a battle based on the
        age and the relative Might advantage.
      </p>
      <div css={RulesPageStyles.probability_table_container}>
        {war_probabilities_table}
      </div>
    </PageSection>
  );

  return (
    <>
      <div css={RulesPageStyles.container}>
        {overview_section}
        {resources_section}
        {/* {game_components_sections} */}
        {card_anatomy_section}
        {tribute_anatomy_section}
        {core_rules_section}
        {card_types_section}
        {set_note_section}
        {card_subtypes_section}
        {other_cards_section}
        {card_outputs_section}
        {war_probabilities_section}
        {playing_online_section}
        {gods_section}
      </div>
      {HoverCardStore.getHoverCardComponent()}
    </>
  );
}

function PageSection(props: {
  title?: string;
  linkName?: string;
  children: React.ReactNode;
  className?: string;
}) {
  let { title, linkName, children } = props;

  let anchor: React.ReactNode = null;
  let hash: React.ReactNode = null;
  if (linkName) {
    anchor = <a id={linkName} />;
    hash = (
      <a
        className="section-hash"
        css={RulesPageStyles2.hashLink}
        href={`#${linkName}`}
      >
        #
      </a>
    );
  }
  return (
    <div css={RulesPageStyles2.section} className={props.className}>
      {anchor}
      <div css={RulesPageStyles2.sectionTitle}>
        {title} {hash}
      </div>
      <div css={RulesPageStyles.section_content}>{children}</div>
    </div>
  );
}

const RulesPageStyles2 = {
  section: css({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'normal',

    '&:hover .section-hash': {
      visibility: 'visible',
    },
    breakInside: 'avoid',
  }),
  sectionTitle: css({
    textAlign: 'center',
    padding: 10,
    paddingBottom: 0,
    marginTop: 10,
    fontSize: 20,
  }),
  hashLink: css({
    color: '#aaa',
    visibility: 'hidden',
  }),
};

const RulesPageStyles = {
  page: css({}),
  container: css({
    display: 'flex',
    flexDirection: 'column',

    width: 750,
    margin: 'auto',
    padding: 20,
    paddingTop: 0,

    backgroundColor: 'rgb(230, 230, 230)',
    color: 'rgb(10, 10, 10)',

    '@media print': {
      backgroundColor: 'transparent',
    },
    [MEDIA_QUERY_MOBILE]: {
      width: '100%',
      boxSizing: 'border-box',
      padding: 10,
    },
  }),
  section_with_image: css({
    display: 'flex',
    flexDirection: 'row',
    padding: 20,

    [MEDIA_QUERY_MOBILE]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  section_with_image_mirrored: css({
    flexDirection: 'row-reverse',
    [MEDIA_QUERY_MOBILE]: {
      flexDirection: 'column',
    },
  }),
  section_with_image_narrow: css({
    marginLeft: 80,
    marginRight: 80,
    [MEDIA_QUERY_MOBILE]: {
      marginLeft: 0,
      marginRight: 0,
    },
  }),
  section_content: css({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '300',
    padding: 10,
    lineHeight: 1.3,
  }),
  section_paragraph: css({
    fontWeight: '300',
    margin: 10,
    lineHeight: 1.3,
  }),
  game_component_container: css({
    columnCount: 2,
    columnGap: 20,
  }),
  game_component: css({
    padding: 15,
    breakInside: 'avoid',
  }),
  section_title_no_padding: css({
    textAlign: 'center',
    marginTop: 10,
    fontSize: 20,
  }),
  imageCaption: css({
    fontStyle: 'italic',
    textAlign: 'center',
    fontSize: 10,
  }),
  card_anatomy_card: css({
    zoom: 3,
    [MEDIA_QUERY_MOBILE]: {
      zoom: 2,
    },
  }),
  image: css({
    paddingLeft: 10,
    paddingRight: 10,
  }),
  imageExamples: css({
    paddingLeft: 10,
    paddingRight: 10,
    margin: 'auto',
    display: 'block',
    height: 50,
  }),
  imagePhaseExample: css({
    padding: 10,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 150,

    [MEDIA_QUERY_MOBILE]: {
      width: '90%',
      height: 'auto',
    },
  }),
  imageGameBoard: css({
    padding: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
  }),
  imagePlayerBoard: css({
    padding: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 400,
    alignSelf: 'center',
  }),
  list: css({
    paddingLeft: 20,
  }),
  list_numbered: css({
    listStyleType: 'decimal',
    marginLeft: 20,
  }),
  listItemSymbolBullet: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    fontWeight: '300',

    padding: 5,
  }),
  symbol: css({
    width: 25,
    height: 25,

    padding: 10,
  }),
  symbol_inline: css({
    width: 12,
    height: 12,

    padding: 1,
  }),
  subsection_title: css({
    fontWeight: 'bold',
    color: 'rgb(20, 60, 140)',
  }),
  section_card_types_container: css({
    columnCount: 2,
    columnGap: 20,

    [MEDIA_QUERY_MOBILE]: {
      columnCount: 1,
    },
  }),
  section_card_types: css({
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '50%',
    lineHeight: 1,
    padding: 10,
    gap: 5,
  }),
  section_card_outputs_container: css({
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  section_card_outputs: css({
    minWidth: 80,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    zoom: 1.5,
  }),
  card_with_note: css({}),
  deck_container: css({
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 4,
  }),
  deck_section: css({
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  }),
  decks_container: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  }),
  probability_table_container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }),
  probability_table: css({
    textAlign: 'center',
    border: '1px solid black',
    alignSelf: 'center',
  }),
  probability_table_cell: css({
    padding: 5,
    border: '1px solid black',
    width: '100px',
  }),
  probability_table_cell_even: css({
    padding: 5,
    border: '1px solid black',
    backgroundColor: 'rgb(190, 190, 190)',
  }),
  probability_table_cell_advantage: css({
    width: '50px',
  }),
  probability_table_header: css({
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '5px',
  }),
} as const;

function session(state: {
  session: Session | undefined;
  gameDefinitions: any;
}) {
  return {
    session: state.session,
    gameDefinitions: state.gameDefinitions,
  };
}

export default connect(session)(RulesPage);
