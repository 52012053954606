import invariant from 'invariant';

export enum CardAffinity {
  Leader = 'Leader',
  Basic = 'Basic',
  Derion = 'Derion',
  Tyros = 'Tyros',
  Zeona = 'Zeona',
  None = 'None',
}

export enum CardType {
  Building = 'Building',
  Unit = 'Unit',
  Event = 'Event',
}

export const CardTypes = [
  CardType.Building,
  CardType.Unit,
  CardType.Event,
] as const;

export const CardSetAffinities = [
  CardAffinity.Derion,
  CardAffinity.Tyros,
  CardAffinity.Zeona,
] as const;

export const CardAffinityToDisplayString: Record<CardAffinity, string> = {
  [CardAffinity.Derion]: 'Derion',
  [CardAffinity.Tyros]: 'Tyros',
  [CardAffinity.Zeona]: 'Zeona',
  [CardAffinity.Basic]: 'Basic',
  [CardAffinity.Leader]: 'Leader',
  [CardAffinity.None]: 'None',
};

// XXX: temporary function while we transition from CardType to CardAffinity
// this returns the old "Resource" string for data migration
// export function getCardTypeString_OLD(cardType: CardAffinity): string {
//   return cardType;
// }

export function getCardAffinityString(affinity: CardAffinity): string {
  return CardAffinityToDisplayString[affinity];
}
export function maybeGetCardAffinityFromString(
  str: string,
): CardAffinity | null {
  return Object.entries(CardAffinityToDisplayString).find(
    ([affinity, value]) => value === str,
  )?.[0] as CardAffinity | null;
}

export function getCardTypeString(cardType: CardType): string {
  return cardType;
}

const CARD_AFFINITY_TO_CHAR: Record<CardAffinity, string> = {
  [CardAffinity.Leader]: '!',
  [CardAffinity.Basic]: '!',
  [CardAffinity.None]: '!',

  [CardAffinity.Derion]: 'D',
  [CardAffinity.Tyros]: 'T',
  [CardAffinity.Zeona]: 'Z',
};

const CARD_TYPE_TO_CHAR: Record<CardType, string> = {
  [CardType.Building]: 'B',
  [CardType.Unit]: 'U',
  [CardType.Event]: 'E',
};

export function getAllCardChars(): string[] {
  return [
    ...Object.values(CARD_AFFINITY_TO_CHAR),
    ...Object.values(CARD_TYPE_TO_CHAR),
  ];
}

export function isCardAffinity(affinity: string): affinity is CardAffinity {
  return CARD_AFFINITY_TO_CHAR[affinity as CardAffinity] !== undefined;
}

export function assertCardAffinity(cardType: string): CardAffinity {
  invariant(isCardAffinity(cardType), 'invalid card affinity: "%s"', cardType);
  return cardType as CardAffinity;
}

export function isCardType(type: string): type is CardType {
  return CARD_TYPE_TO_CHAR[type as CardType] !== undefined;
}

export function assertCardType(type: string): CardType {
  invariant(isCardType(type), 'invalid card type: %s', type);
  return type as CardType;
}

export function maybeCharToCardAffinity(char: string): CardAffinity | null {
  for (const [key, value] of Object.entries(CARD_AFFINITY_TO_CHAR)) {
    if (value === char) {
      return key as CardAffinity;
    }
  }
  return null;
}
export function affinityCharToCardAffinity(char: string): CardAffinity {
  const ret = maybeCharToCardAffinity(char);
  invariant(ret, 'invalid card affinity char: %s', char);
  return ret;
}

export function getCardAffinityChar(cardType: CardAffinity): string {
  return CARD_AFFINITY_TO_CHAR[cardType];
}

export function maybeCharToCardType(char: string): CardType | null {
  for (const [key, value] of Object.entries(CARD_TYPE_TO_CHAR)) {
    if (value === char) {
      return key as CardType;
    }
  }
  return null;
}

export function typeCharToCardType(char: string): CardType {
  const ret = maybeCharToCardType(char);
  invariant(ret, 'invalid card sub type char: %s', char);
  return ret;
}

export function getCardTypeChar(type: CardType): string {
  return CARD_TYPE_TO_CHAR[type];
}
