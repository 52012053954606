const CHAR_REPLACEMENT_MAP: Record<string, string> = {
  B: '!',
  L: '!',
  N: '!',

  R: 'D',
  C: 'T',
  P: 'Z',

  D: 'B',
};
const CHAR_REPLACEMENT_REGEX = new RegExp(
  Object.keys(CHAR_REPLACEMENT_MAP).join('|'),
  'g',
);
export function migrateCardChars2To3(
  str: string | undefined,
): string | undefined {
  if (!str) {
    return undefined;
  }
  return str.replace(
    CHAR_REPLACEMENT_REGEX,
    (match) => CHAR_REPLACEMENT_MAP[match],
  );
}
