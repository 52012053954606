/** @jsx jsx */
/** @jsxFrag */
import { jsx } from '@emotion/react';
import React, { StrictMode, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { connect, Provider, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { applyMiddleware, createStore, Store } from 'redux';
import { thunk } from 'redux-thunk';

import { setSession } from './actions';
import { MobileContext } from './MobileContext';
import MythosReducer from './reducers';

import Session from '../common/utils/Session';
import AllCardsPage from './AllCardsPage';
import { startChannelListener } from './ChannelListener';
import GameHistoryListPage from './GameHistoryListPage';
import GamePage from './GamePage';
import LobbyListPage from './LobbyListPage';
import LobbyPage from './LobbyPage';
import LoginPage from './LoginPage';
import PageChrome from './PageChrome';
import RulesPage from './RulesPage';

import '../assets/css/style.css';
import CardDebugPage from './CardDebugPage';
import PrintCardsPage from './PrintCardsPage';
// unused but necessary for this asset to be bundled

function select_session(state: any) {
  return { session: state.session as Session | undefined };
}

const MainPage = connect(select_session)(
  class extends React.Component<{ session?: Session }> {
    render() {
      var session = this.props.session;
      const params = new URLSearchParams(window.location.search);
      const redirect = params.get('redirect');

      if (session && redirect) {
        return <Navigate to={redirect} replace />;
      }

      var loginElement = !session ? (
        <LoginPage {...this.props} redirect={redirect} />
      ) : (
        <LobbyListPage />
      );

      return loginElement;
    }
  },
);

function PageLayoutRoute() {
  const session = useSelector((state: any) => state.session);
  return (
    <PageChrome session={session}>
      <Outlet />
    </PageChrome>
  );
}

function NotFoundHandler(props: {}) {
  return <p>Page not found</p>;
}

function App(props: { store: Store<any> }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StrictMode>
      <MobileContext.Provider value={isMobile}>
        <Provider store={props.store}>
          <BrowserRouter>
            <Routes>
              <Route
                element={
                  <RequireSession>
                    <Outlet />
                  </RequireSession>
                }
              >
                <Route path="/game/:gameID" element={<GamePage />} />
              </Route>
              <Route element={<PageLayoutRoute />}>
                <Route path="/" element={<MainPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/all-cards" element={<AllCardsPage />} />
                <Route path="/rules" element={<RulesPage />} />

                <Route
                  element={
                    <RequireSession>
                      <Outlet />
                    </RequireSession>
                  }
                >
                  <Route path="/lobby/:lobbyID" element={<LobbyPage />} />
                  <Route path="/lobby-list" element={<LobbyListPage />} />
                </Route>

                <Route
                  element={
                    <RequireDeveloper>
                      <Outlet />
                    </RequireDeveloper>
                  }
                >
                  <Route
                    path="/game-history-list"
                    element={<GameHistoryListPage history={history} />}
                  />
                  <Route path="/print" element={<PrintCardsPage />} />
                  <Route path="/debug_card" element={<CardDebugPage />} />
                </Route>
              </Route>

              <Route path="*" element={<NotFoundHandler />} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </MobileContext.Provider>
    </StrictMode>
  );
}

function RequireSession({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const session = useSelector(
    (state: any) => state.session as Session | undefined,
  );

  if (!session) {
    return (
      <Navigate
        to={`/?redirect=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return children;
}

function RequireDeveloper({ children }: { children: React.ReactNode }) {
  const session = useSelector(
    (state: any) => state.session as Session | undefined,
  );

  if (
    process.env.NODE_ENV !== 'development' &&
    (!session || !session.getUser().isDev)
  ) {
    return <Navigate to="/" replace />;
  }

  return children;
}

window.onload = () => {
  const json_session = window['__session' as any];
  const session = json_session ? Session.fromJSON(json_session) : null;

  let store = applyMiddleware(thunk)(createStore)(MythosReducer);
  store.dispatch(setSession(session));

  startChannelListener(store, session);
  let container = document.getElementById('content');
  if (!container) {
    throw new Error('No element with id "content"');
  }

  const root = createRoot(container);
  root.render(<App store={store} />);
};
