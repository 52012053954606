import {
  affordableCards,
  BidPreferences,
  calculateBidFromCardValueThresholds,
  calculateCardValues,
  CardValuePreferences,
} from '@mythos/game/AIUtilities';
import Bot from '@mythos/game/Bot';
import { CardAffinity } from '@mythos/game/CardTypes';
import { InflatedGame } from '@mythos/game/Game';
import * as Rules from '@mythos/game/Rules';
import { debug_log } from '@mythos/game/Utility';
import { randomSelect } from '@mythos/utils/utils';

export default class EasyBot extends Bot {
  // card prefences
  cardValuePreferences_: CardValuePreferences;
  bidPreferences_: BidPreferences;
  // easybot will randomly select any card that is within preferredCardValueThreshold of the best value card
  preferredCardValueThreshold_: number;

  constructor(
    preferences?: CardValuePreferences,
    bidPreferences?: BidPreferences,
    preferredCardValueThreshold?: number,
  ) {
    super();
    this.cardValuePreferences_ = preferences || {
      tribute_value: 0.5,
      card_output_values_by_age: {
        // TODO: revisit -- factor in turn/age etc and current production
        gold: [0.5, 0.5, 0.5],
        military: [0.5, 0.5, 0.25],
      },

      target_gold_output_by_age: [4, 7, 8],
      military_supremacy_target: 1,
      military_supremacy_preference: 0.5,
      card_flat_favor_preference_by_age: [1, 1.5, 2],
      target_card_type_by_age: {
        [CardAffinity.Leader]: [1, 1, 1],
        [CardAffinity.Basic]: [0, 0, 0],
        [CardAffinity.Derion]: [2, 4, 5],
        [CardAffinity.Tyros]: [2, 3, 5],
        [CardAffinity.Zeona]: [2, 3, 5],
        [CardAffinity.None]: [0, 0, 0],
      },
      unaffordable_value_penalty: 10,

      // Basic Bot 2 preferences - keeping dumb for default
      card_cost_value_reduction_by_age: [0, 0, 0],
    };
    this.bidPreferences_ = bidPreferences || {
      low_value_threshold_by_age: [1, 2, 3],
      high_value_threshold_by_age: [8, 16, 24],
      conserve_military_factor_by_age: [0, 0, 0],
      bid_nothing_threshold_by_age: [0, 0, 0],
      bid_nothing_probability_by_age: [0, 0, 0],
      random_value_range: [-1, 1],
    };
    this.preferredCardValueThreshold_ = preferredCardValueThreshold || 8;
  }

  getPreferences(): CardValuePreferences {
    return this.cardValuePreferences_;
  }

  setPreferences(preferences: CardValuePreferences) {
    this.cardValuePreferences_ = preferences;
  }

  computeBid(game_state: InflatedGame, user_id: string) {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);

    let purchaseable_cards = affordableCards(context, game_state.table);

    // Note: this could be smarter to avoid giving away clash tributes, denying players etc
    if (purchaseable_cards.affordable.length === 0) {
      debug_log(`<!> ${user_id}: cant afford anything, picking randomly`);
      let allIndices = game_state.table.map((_, index) => index);
      return {
        military: 0,
        tradeRowIndex: randomSelect(allIndices),
      };
    }

    let card_descending_values = calculateCardValues(
      game_state,
      user_id,
      purchaseable_cards.affordable.map(([card, _cost]) => card),
      this.cardValuePreferences_,
    );
    const maxValue = card_descending_values[0].value;
    const cardValue = randomSelect(
      card_descending_values.filter((value_data) => {
        return maxValue - value_data.value <= this.preferredCardValueThreshold_;
      }),
    );

    const card = cardValue.card;
    const militaryBid = calculateBidFromCardValueThresholds(
      cardValue,
      this.bidPreferences_,
      context,
    );

    return {
      military: militaryBid,
      tradeRowIndex: game_state.table.indexOf(card),
    };
  }

  override computeResolutionSelection(
    game_state: InflatedGame,
    user_id: string,
  ): Rules.ResolutionSelection {
    const player = this.getPlayer(game_state, user_id);
    const context = Rules.makeContextFromGame(player, game_state);
    let selectedCardID = player.selectedCard?.id;
    let affordableBasicCards = game_state.basicPile.filter(
      (card) => Rules.canAffordCard(context, card).canAfford,
    );
    const card_descending_values = calculateCardValues(
      game_state,
      user_id,
      affordableBasicCards,
      this.cardValuePreferences_,
    );
    let basicCardID = card_descending_values[0].card.id;

    let isAffordable =
      selectedCardID &&
      Rules.canAffordCard(context, game_state.cardsByID[selectedCardID])
        .canAfford;

    return {
      cardIDToGain:
        isAffordable && selectedCardID ? selectedCardID : basicCardID,
    };
  }
}
